import {RAKE_TYPE, ROOM_TYPE} from "../../dataset";
import React, {useMemo} from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
  color: #FFF;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.7;

  @media ${MEDIA_DESKTOP} {
    font-size: 12px;
    margin-left: 16px;
    margin-top: 0;
    justify-content: center;
  }
`;

function StatusBoard(
  {
    roomType,
    SB,
    BB,
    ante,
    rakeType,
    rake,
    myRank,
    totalMember,
    straddle,
    timerText,
  }: {
    roomType: ROOM_TYPE;
    SB: number;
    BB: number;
    ante: number;
    rakeType: RAKE_TYPE;
    rake: number;
    myRank: number;
    totalMember: number;
    straddle: boolean;
    timerText: string;
  }
) {

  const ringBetText = useMemo<string>(() => {
    let chunks = [];

    if (SB > 0 && BB > 0) {
      chunks.push(`${SB.toLocaleString()} / ${BB.toLocaleString()}`);
    }
    if (ante > 0) {
      chunks.push(`앤티 ${ante.toLocaleString()}`);
    }

    return chunks.join(', ');
  }, [SB, BB, ante]);

  const rakeText = useMemo<string>(() => {
    if (rakeType === RAKE_TYPE.HAND_RAKE) {
      return `핸드레이크 ${rake.toLocaleString()}`;
    } else if (rakeType === RAKE_TYPE.POT_RAKE) {
      return `팟레이크 ${rake}%`;
    }

    return '';
  }, [rakeType, rake]);

  return <Wrapper className="status-board">
    {
      roomType == ROOM_TYPE.TOURNAMENT && timerText && <>
        <div>{timerText}</div>
        {
          ringBetText.length > 0 && (
            <div>{ringBetText}</div>
          )
        }
        {
          Boolean(myRank && totalMember) && (
            <div>내 순위 {myRank} / {totalMember}</div>
          )
        }
      </>
    }
    {
      roomType == ROOM_TYPE.RING && <>
        {
          ringBetText.length > 0 && (
            <div>{ringBetText}</div>
          )
        }
        {
          rakeText.length > 0 && (
            <div>{rakeText}</div>
          )
        }
        {
          Boolean(straddle) && (
            <div>스트래들 있음</div>
          )
        }
      </>
    }
  </Wrapper>;
}

export default StatusBoard;