import styled from "styled-components";
import {BET_TYPE, GamePlayer, ROOM_JOIN_STATUS} from "../../dataset";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import moment from "moment";
import ActionTag from "./ActionTag";
import PokerCard from "./PokerCard";
import ProgressBar from "../common/ProgressBar";
import Const from "../../Const";
import useScreenOrientation from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{ folded: boolean }>`
  position: absolute;
  left: -100%;
  top: -100%;
  width: 54px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;

  ${p => p.folded && `
    opacity: 0.6;
  `};

  > div.top {
    width: 100%;
    position: relative;

    > .profileImg {
      border: 1px solid #FFF;
      width: 34px;
      height: 34px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 0 auto;

      &[data-winner=true] {
        border: 2px solid #FFEDAC;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
      }
    }

    .winner-badge {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  > div.bottom {
    width: 100%;
    position: relative;
    margin-top: -6px;

    > .profileName {
      border-radius: 5px;
      border-top: 0.4px solid rgba(255, 255, 255, 0.00);
      background: radial-gradient(95.31% 95.31% at 50% 100%, rgba(182, 182, 182, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #181A1D;
      padding: 2px 4px;
      position: relative;
      z-index: 1;
      text-align: center;

      > .nickname {
        font-size: 10px;
        font-weight: 500;
      }

      > .prize {
        color: ${p => p.folded ? '#FFFFFF' : 'rgb(255, 199, 0)'};
        text-align: center;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }
`;

const CardDeck = styled.div<{ isLandscape?: boolean }>`
  position: absolute;
  ${p => p.isLandscape ? `
  z-index: 3;
  left: 66px;
  top: 5px;
  ` : `
    bottom: -8px;
  right: 2px;
  `}
  > img {
    ${p => p.isLandscape ? `
      position: absolute;
      width: 24px;
      &:first-child {
        transform: translate(-8px, 0) rotate(-5deg);
      }

      &:last-child {
        transform: translate(8px, 0) rotate(5deg);
      }
    ` : `
      width: 50%;
      &:first-child {
        transform: translate(3px, 0) rotate(-5deg);
      }

      &:last-child {
        transform: translate(-3px, 0) rotate(5deg);
      }
    `}

    height: auto;
    box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.40);

 
  }
`;


function HistoryPlayer(
  {
    userId,
    seat,
    nickname,
    profileImg,
    folded,
    winner,
    prize,
    cards,
  }: {
    userId: number;
    seat: number;
    nickname: string;
    profileImg: string;
    folded: boolean;
    winner: boolean;
    prize: number;
    cards: number[];
  }
): JSX.Element {
  const orientation = useScreenOrientation();
  return <Wrapper className="history-player" data-seat={seat} data-user={userId} folded={folded}>
    {
      orientation === 'landscape' && <>
        {
          cards.length === 2 && (
            <CardDeck isLandscape={true}>
              <img src={`/images/card/style-1/${cards[0]}.png`}/>
              <img src={`/images/card/style-1/${cards[1]}.png`}/>
            </CardDeck>
          )
        }
      </>
    }
    <div className="top">
      <div className="profileImg"
           data-winner={winner}
           style={{backgroundImage: `url(/new-image/profile/profile_0${Number(profileImg) + 1}.png)`}}
      />
      {
        orientation === 'portrait' && <>
          {
            cards.length === 2 && (
              <CardDeck>
                <img src={`/images/card/style-1/${cards[0]}.png`}/>
                <img src={`/images/card/style-1/${cards[1]}.png`}/>
              </CardDeck>
            )
          }
        </>
      }

      {
        winner && (
          <img className="winner-badge" src={"/images/winner_badge.svg"}/>
        )
      }
    </div>
    <div className="bottom">
      <div className="profileName">
        <div className="nickname">{nickname}</div>
        {
          folded ? (
            <div className="prize">FOLD</div>
          ) : (
            <div className="prize">{prize.toLocaleString()}</div>
          )
        }
      </div>
    </div>
  </Wrapper>;
}

export default HistoryPlayer;
