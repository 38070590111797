import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MyInfo} from "../dataset";
import {requestMyInfo} from "../api";

const Wrapper = styled.div`
  width: 100%;
  min-width: 311px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
`;

const MinMaxBuyIn = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  > div {
    display: flex;
    color: #FFF;
    font-size: 10px;
    font-weight: 600;

    .item-name {
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      opacity: 0.5;
      margin-right: 2px;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2D2D2D;

  input {
    width: 100%;
    flex: 1;
    font-size: 32px;
    color: #FFF;
    padding: 8px;
    background-color: transparent;
    outline: none;
    border: none;

    &::placeholder {
      color: #FFF;
      opacity: 0.5;
    }
  }
`;

const ShortcutButtons = styled.div`
  display: flex;
  gap: 4px;
  padding: 16px 0;

  > div {
    flex: 1;
    padding: 12px;
    border-radius: 6px;
    background: #1F1F1F;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &.max {
      flex: initial;
    }
  }
`;

const BalanceInfo = styled.div`
  margin: 26px 0 42px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .row {
    display: flex;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .item-name {
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
      opacity: 0.5;
      margin-left: 4px;
      margin-right: 2px;
    }
  }
`;

const BuyInButton = styled.div<{
  disabled?: boolean
}>`
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;

  ${p => p.disabled && `
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.20);
    cursor: initial;
  `};
`;

interface BuyInModalProps {
  bigBlind: number;
  minBuyIn: number;
  maxBuyIn: number;
  onClickBuyIn: (buyIn: number) => void;
}

function BuyInModal(
  {
    bigBlind,
    minBuyIn,
    maxBuyIn,
    onClickBuyIn
  }: BuyInModalProps
) {
  const [value, setValue] = useState<number>(0);
  const [myInfo, setMyInfo] = useState<MyInfo>();

  const isValidValue = useMemo<boolean>(() => {
    if (!value || value < minBuyIn || value > maxBuyIn) {
      return false;
    } else if (!myInfo || myInfo.money < value) {
      return false;
    }

    return true;
  }, [value, minBuyIn, maxBuyIn, myInfo?.money]);

  useEffect(() => {
    requestMyInfo().then( (v:any) => setMyInfo(v.info));
  }, []);

  const handleChange = useCallback((e: any) => {
    setValue(Number(e.target?.value ?? 0))
  }, []);

  const handleBuyIn = () => {
    if (!isValidValue)
      return;

    onClickBuyIn && onClickBuyIn(Number(value));
  }

  const handleSum = (num: number) => {
    let newValue;

    if (myInfo && num === -1) {
      newValue = myInfo?.money;
    } else {
      newValue = Number(value || 0) + num;
    }

    setValue(Math.min(maxBuyIn, newValue));
  }

  return <Wrapper>
    <Title>바이인</Title>
    <MinMaxBuyIn>
      <div>
        <div className="item-name">MIN</div>
        {minBuyIn.toLocaleString()} ({minBuyIn / bigBlind}BB)
      </div>
      <Flex1/>
      <div>
        <div className="item-name">MAX</div>
        {maxBuyIn.toLocaleString()} ({maxBuyIn / bigBlind}BB)
      </div>
    </MinMaxBuyIn>
    <InputWrapper>
      <img src="/images/ic_buyin_chips.svg"/>
      <input type="number" value={value || ''} placeholder="충전 수량" onChange={handleChange}/>
    </InputWrapper>
    <ShortcutButtons>
      <div onClick={() => handleSum(5000)}>5,000</div>
      <div onClick={() => handleSum(10000)}>10,000</div>
      <div onClick={() => handleSum(100000)}>100,000</div>
      <div className="max" onClick={() => handleSum(-1)}>최대</div>
    </ShortcutButtons>
    <BalanceInfo>
      <div className="row">
        <span className="item-name">마이칩</span>
        {(myInfo?.money ?? 0).toLocaleString()}
        <span className="item-name"> - 바이인</span>
        {value.toLocaleString()}
      </div>
      <div className="row">
        <div className="item-name">= 밸런스</div>
        {((myInfo?.money ?? 0) - value).toLocaleString()}
      </div>
    </BalanceInfo>
    <BuyInButton disabled={!isValidValue} onClick={handleBuyIn}>바이인</BuyInButton>
  </Wrapper>
}

export default BuyInModal