import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {DialogProps, dialogState} from "../recoil/Dialog";
import {useCallback} from "react";

const Wrapper = styled.div`
  min-width: 311px;
  min-height: 236px;
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(55, 57, 61);
  background: rgb(15, 16, 17);
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  word-break: break-word;
`;

const Content = styled.div`
  flex: 1;
  color: rgb(159, 159, 159);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: break-word;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 11px;
`;

const Button = styled.div<{ cancel?: boolean }>`
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: -moz-linear-gradient( 90deg, rgb(31,32,33) 0%, rgb(41,42,42) 59%, rgb(47,49,48) 61%, rgb(53,55,53) 71%, rgb(67,69,69) 92%, rgb(53,55,53) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(31,32,33) 0%, rgb(41,42,42) 59%, rgb(47,49,48) 61%, rgb(53,55,53) 71%, rgb(67,69,69) 92%, rgb(53,55,53) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(31,32,33) 0%, rgb(41,42,42) 59%, rgb(47,49,48) 61%, rgb(53,55,53) 71%, rgb(67,69,69) 92%, rgb(53,55,53) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
  ${p => p.cancel ? `
  color: white;
  `: `
  color: rgb(113,215,45);
  `}
  border-radius: 8px;
  padding: 14px;
  transition: all 0.1s linear;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.7);
  }
`;

function Dialog({dialog}: { dialog: DialogProps }) {
  const handleConfirm = useCallback(() => {
    dialog?.onConfirm && dialog.onConfirm();
  }, [dialog?.onConfirm]);

  return <Wrapper>
    <Title>{dialog.title}</Title>
    <Content dangerouslySetInnerHTML={{__html: dialog.text || ''}}></Content>
    <ButtonWrapper>
      {
        dialog.confirm && (
          <Button cancel onClick={dialog.onCancel}>{dialog.cancelText || '취소'}</Button>
        )
      }
      <Button onClick={handleConfirm}>{dialog.confirmText || '확인'}</Button>
    </ButtonWrapper>
  </Wrapper>;
}

export default Dialog;
