import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {connectionData, requestDirectLinkLogin, requestLogin, useSocketIsConnect} from "../api";
import {sha256} from "js-sha256";
import useDialog from "../hooks/useDialog";
import useQueryParams from "../hooks/useQueryParams";
import {useHistory} from "react-router-dom";
import useScreenOrientation from "../hooks/useScreenOrientation";

const Wrapper = styled.div`
  text-align:center;
`;

function DirectLink() {
  const queryParams = useQueryParams();
  const history = useHistory();
  const [conn] = useSocketIsConnect();
  const orientation = useScreenOrientation();

  useEffect(function(){
    let query = queryParams.get("q");
    let groupId = queryParams.get("groupId");
    if(conn.isConnected) {
      const platform = orientation === 'landscape' ? 'pc' : 'mobile'
      const gameDetailUrl = `${process.env.REACT_APP_HOST_URL}/${platform}/game/gameInfo?groupId=${groupId}`
      requestDirectLinkLogin(query).then(v=>{
        if(v.isEnd == true){
          alert("이미 종료된 토너먼트입니다.");
          window.location.href = gameDetailUrl;
        }else if(v.groupId == -1){
          alert("이미 만료된 입장입니다. 다시 입장해주세요.");
          window.location.href = gameDetailUrl;
          return ;
        }else{
          if(typeof(v.roomId) != "number" || v.roomId == 0){
            alert("아직 게임이 시작하지 않았습니다! 잠시 후 다시 입장해주세요.");
            window.location.href = gameDetailUrl;
          }else{
            history.push("/game?id="+v.roomId);
          }
        }
      })
    }
  },[conn.isConnected, conn.isLogin, queryParams])

  return  <Wrapper>
    접속 중...
  </Wrapper>
}

export default DirectLink;