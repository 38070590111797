import moment from "moment/moment";

export const wait = async (ms: number) => {
    await new Promise<void>((r) => setTimeout(() => r(), ms));
}

export const convertMinutes = (time: number) => {
    if ((time % 60) === 0) {
        return moment(time * 1000).format("m분")
    } else {
        return moment(time * 1000).format("m분 s초")
    }
}


export const fixedNumber = (value: number, fixed: number) => {
    //fixed 자리수까지 소수점
    return Number(value.toFixed(fixed))
}

export const closeWindow = () => {
    window.open('', '_self', '');
    window.close();
}

export const getKoreanNumber = (targetNum:number) => {
    const koreanUnits = ['조', '억', '만', ''];
    const unit = 10000;
    let answer = '';

    while (targetNum > 0) {
        const mod = targetNum % unit;
        const modToString = mod.toString().replace(/(\d)(\d{3})/, '$1,$2');
        targetNum = Math.floor(targetNum / unit);
        const textUnit = koreanUnits.pop()
        if(textUnit === '만'){
            answer = `${modToString}${textUnit}`;
        }else{
            answer = `${modToString}${textUnit}${answer}`;
        }

    }
    return answer;
}
