import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {requestCurrentRooms, requestJoinRoom, useSocketIsConnect} from "../api";
import {useRecoilState} from "recoil";
import {connectionState} from "../recoil/Connection";

const RejoinCheck: React.FC = () => {
    const history = useHistory();
    const [conn] = useSocketIsConnect();

    const [connected, setConnected] = useRecoilState(connectionState);

    useEffect(() => {
      (async () => {
        if (connected) {
          if (!conn.isConnected) {
            setConnected(false);
          }
        } else {
          if (conn.isConnected && conn.isLogin) {
            // const {list: rooms} = await requestCurrentRooms();
            // const sortedRooms = rooms.sort((a, b) => a.type - b.type);
            // if (sortedRooms.length > 0 && sortedRooms[0].roomId > 0) {
            //   const roomId = sortedRooms[0].roomId;
            //   await requestJoinRoom(roomId);
            //   history.push("/game?id=" + roomId);
            // }

            setConnected(true);
          }
        }
      })();
    }, [connected, conn.isConnected, conn.isLogin]);

    return <>
      {/* <div>conn : {conn.isConnected ? "1":"0"}</div>
    <div>login : {conn.isLogin ? "1":"0"}</div>
    <div>room : {room > 0 ? "1":"0"}</div> */}
    </>;
  }
;
export default RejoinCheck;