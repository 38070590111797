import {useCallback, useEffect, useRef} from "react";
import {wait} from "../utils/common";
import {GamePlayer, ROOM_JOIN_STATUS, winnerModel} from "../dataset";
import useScreenOrientation from "./useScreenOrientation";

const TABLE_PORTRAIT_ORIGIN_WIDTH = 1286/2;
const TABLE_PORTRAIT_ORIGIN_HEIGHT = 1654/2;
const TABLE_LANDSCAPE_ORIGIN_WIDTH = 1561;
const TABLE_LANDSCAPE_ORIGIN_HEIGHT = 729;

const RECTS_SEATS_PORTRAIT = [
  [0.23, 0.78], //0
  [0.182, 0.57], //1
  [0.198, 0.39], //2
  [0.21, 0.21], //3
  [0.35, 0.05], //4
  [0.65, 0.05], //5
  [0.79, 0.21], //6
  [0.81, 0.39], //7
  [0.82, 0.57], //8
];
const RECTS_DEALER_BUTTON_PORTRAIT = [
  [0.5, 0.66],  //0
  [0.28, 0.60], //1
  [0.29, 0.42], //2
  [0.33, 0.25], //3
  [0.40, 0.132],//4
  [0.62, 0.132],//5
  [0.68, 0.25], //6
  [0.71, 0.42], //7
  [0.707, 0.60], //8
];
const RECTS_PLAYER_POT_PORTRAIT = [
  [0.5, 0.62], //0
  [0.34, 0.60], //1
  [0.35, 0.42], //2
  [0.39, 0.276], //3
  [0.43, 0.176], //4
  [0.58, 0.176], //5
  [0.62, 0.276], //6
  [0.65, 0.42], //7
  [0.66, 0.60], //8
];
const COMMUNITY_CARDS_Y_PORTRAIT = 0.5;
const MY_CARDS_Y_PORTRAIT = 0.8;
const POTS_Y_PORTRAIT = 0.35;
const TABLE_HOLE_Y_PORTRAIT = 0.5;

const RECTS_SEATS_LANDSCAPE = [
  [0.35, 0.9],
  [0.02, 0.76],
  [0.02, 0.21],
  [0.27, -0.07],
  [0.5, -0.07],
  [0.73, -0.07],
  [0.985, 0.21],
  [0.985, 0.76],
  [0.7, 0.9],
];
const RECTS_DEALER_BUTTON_LANDSCAPE = [
  [0.35, 0.7],
  [0.1, 0.67],
  [0.1, 0.3],
  [0.27, 0.15],
  [0.5, 0.15],
  [0.73, 0.15],
  [0.91, 0.31],
  [0.91, 0.68],
  [0.7, 0.7],
];
const RECTS_PLAYER_POT_LANDSCAPE = [
  [0.42, 0.71],
  [0.15, 0.69],
  [0.15, 0.34],
  [0.27, 0.24],
  [0.5, 0.24],
  [0.73, 0.24],
  [0.86, 0.34],
  [0.86, 0.69],
  [0.62, 0.71],
];
const COMMUNITY_CARDS_Y_LANDSCAPE = 0.53;
const MY_CARDS_Y_LANDSCAPE = 0.8;
const POTS_Y_LANDSCAPE = 0.41;
const TABLE_HOLE_Y_LANDSCAPE = 0.5;


function useGameLayout(
  {
    dealerIndex,
    maxTableMember,
    pots
  }: {
    dealerIndex: number,
    maxTableMember: number
    pots: number[]
  }
) {
  const tableRectRef = useRef<{
    x: number,
    y: number,
    w: number,
    h: number
  }>({
    x: 0,
    y: 0,
    w: 0,
    h: 0
  });
  const prevDealerIndex = useRef<number>(dealerIndex);

  const orientation = useScreenOrientation();

  const rearrangeLayout = () => {
    const container = document.getElementsByClassName('game-table')[0] as HTMLDivElement;
    if (!container || container.offsetHeight == 0) {
      return;
    }

    const isPortrait = orientation === 'portrait';
    const tableOriginWidth = isPortrait ? TABLE_PORTRAIT_ORIGIN_WIDTH : TABLE_LANDSCAPE_ORIGIN_WIDTH;
    const tableOriginHeight = isPortrait ? TABLE_PORTRAIT_ORIGIN_HEIGHT : TABLE_LANDSCAPE_ORIGIN_HEIGHT;

    let width = container.offsetWidth;
    let height = container.offsetHeight;

    const containerAspect = container.offsetWidth / container.offsetHeight;
    const originAspect = tableOriginWidth / tableOriginHeight;
    if (containerAspect > originAspect) {
      width = height * (tableOriginWidth / tableOriginHeight);
    } else if (containerAspect < originAspect) {
      height = width * (tableOriginHeight / tableOriginWidth);
    }

    const left = container.offsetLeft + (container.offsetWidth - width) / 2;
    const top = container.offsetTop + (container.offsetHeight - height) / 2;
    const cx = left + width / 2;
    const cy = top + height / 2;

    tableRectRef.current = {
      x: left,
      y: top,
      w: width,
      h: height
    };

    const scale = Math.min(width / tableOriginWidth, isPortrait ? 1.5 : 1);

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.transform = "scale(" + scale + ")";
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const communityCardsWrapper = document.getElementsByClassName("community-cards-wrapper") as any;
    const fieldPotsWrapper = document.getElementsByClassName("field-pots") as any;
    const myCardsWrapper = document.getElementsByClassName("my-cards-wrapper") as any;
    const dealerButton = document.getElementsByClassName("dealer-button") as any;
    const tableHole = document.getElementsByClassName("table-hole") as any;

    const communityCardsY = isPortrait ? COMMUNITY_CARDS_Y_PORTRAIT : COMMUNITY_CARDS_Y_LANDSCAPE;
    const myCardsY = isPortrait ? MY_CARDS_Y_PORTRAIT : MY_CARDS_Y_LANDSCAPE;
    const fieldPotsY = isPortrait ? POTS_Y_PORTRAIT : POTS_Y_LANDSCAPE;
    const tableHoleY = isPortrait ? TABLE_HOLE_Y_PORTRAIT : TABLE_HOLE_Y_LANDSCAPE;

    position(communityCardsWrapper, 0, cx, top + height * communityCardsY);
    position(myCardsWrapper, 0, cx, top + height * myCardsY);
    position(fieldPotsWrapper, 0, cx, top + height * fieldPotsY);
    position(tableHole, 0, cx, top + height * tableHoleY);

    const seats = document.getElementsByClassName("game-seat") as any;
    const pots = document.getElementsByClassName("game-player-pot") as any;
    const seatRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_SEATS_PORTRAIT : RECTS_SEATS_LANDSCAPE));
    const potRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_PLAYER_POT_PORTRAIT : RECTS_PLAYER_POT_LANDSCAPE));
    const dealerBtnRects = JSON.parse(JSON.stringify(isPortrait ? RECTS_DEALER_BUTTON_PORTRAIT : RECTS_DEALER_BUTTON_LANDSCAPE));

    let deleteSeatIndices: number[] = [];
    if (maxTableMember === 5) {
      deleteSeatIndices = isPortrait ? [6, 5, 4, 3] : [8, 7, 6, 5];
    } else if (maxTableMember === 6) {
      deleteSeatIndices = isPortrait ? [6, 5, 4] : [7, 6, 5];
    } else if (maxTableMember === 8) {
      if (isPortrait) {
        // 8자리일 때는 모바일에서만 최상단 한 자리 삭제
        seatRects[4][0] = 0.5;
        potRects[4][0] = 0.5;
        dealerBtnRects[4][0] = 0.5;
        deleteSeatIndices = [5];
      }
    }

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      potRects.splice(seatIndex, 1);
      dealerBtnRects.splice(seatIndex, 1);
    }

    // 좌석과 팟 위치 설정
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
      position(pots, i, left + width * potRects[i][0], top + height * potRects[i][1]);
    }

    // 딜러버튼 위치 설정
    if (dealerIndex !== -1) {
      // 딜러버튼의 위치가 바뀐 경우 transition 활성화
      if (prevDealerIndex.current !== dealerIndex) {
        dealerButton[0].style.transition = 'all 1s ease-in-out';
        prevDealerIndex.current = dealerIndex;
      } else if (dealerButton[0].style.transition !== '') {
        dealerButton[0].style.transition = '';
      }
      position(dealerButton, 0, left + width * dealerBtnRects[dealerIndex][0], top + height * dealerBtnRects[dealerIndex][1]);
    } else {
      position(dealerButton, 0, cx, cy);
    }
  };

  const coinMove = useCallback(async (from: DOMRect, to: DOMRect, amount: number, isSmall = false) => {
    if (amount >= 0) {
      let coin3 = isSmall ? 1 : 2;
      let coin2 = isSmall ? 1 : 3;
      let coin1 = isSmall ? 1 : 3;


      const wrapper = document.querySelector(".coin-move-wrapper");
      if (!wrapper) {
        return;
      }

      const arr = [coin1, coin2, coin3];
      for (let c in arr) {
        for (let i = 0; i < arr[c]; i++) {
          const div = document.createElement("div");
          div.className = "move-coin";
          div.style.backgroundImage = `url(/new-image/chip/ic_chip_0${Number(c) + 1}.png)`;
          div.style.transform = `translate(${from.x + from.width / 2 }px,${from.y + from.height / 2 - 20}px)`;
          wrapper.append(div);

          setTimeout(async () => {
            try {
              await wait(10);
              div.style.transform = `translate(${to.x + to.width / 2 - 5 }px,${to.y + to.height / 2 - 20}px)`;
              await wait(200);
              div.style.opacity = "0";
              div.style.width = "0px";
              div.style.height = "0px";
              await wait(200);
              div.remove();
            } finally {
            }
          }, 10);

          await wait(50);
        }
      }
    }
  }, [orientation]);

  const moveCoinToPlayerPot = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.getElementsByClassName(`seat-${userId}`)[0];
    const pot = document.getElementsByClassName(`pot-${userId}`)[0];
    if (seat && pot) {
      await coinMove(
        seat.getBoundingClientRect(),
        pot.getBoundingClientRect(),
        amount
      );
    }
  }, [coinMove]);

  const moveCoinToGamePot = useCallback(async (delay: number = 0) => {
    await wait(delay);
    const fieldPot = document.querySelector(".field-pot");
    if (fieldPot) {
      const potElems = [...document.querySelectorAll(".game-player-pot > img") as any];
      const promises = [];
      for (let potElem of potElems) {
        const amount = Number(potElem.getAttribute('data-amount'));
        if (amount > 0) {
          promises.push(coinMove(
            potElem.getBoundingClientRect(),
            fieldPot.getBoundingClientRect(),
            amount
          ));
        }
      }
      await Promise.allSettled(promises);
    }
  }, []);

  const moveCoinAnte = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.getElementsByClassName(`seat-${userId}`)[0];
    const fieldPot = document.querySelector(".field-pot");
    if (seat && fieldPot) {
      await coinMove(
        seat.getBoundingClientRect(),
        fieldPot.getBoundingClientRect(),
        amount,
        true
      );
    }
  }, []);

  const moveCoinRake = useCallback(async (userId: number, amount: number, delay: number = 0) => {
    await wait(delay);
    const seat = document.getElementsByClassName(`seat-${userId}`)[0];
    const tableHole = document.querySelector(".table-hole");
    if (seat && tableHole) {
      await coinMove(
        seat.getBoundingClientRect(),
        tableHole.getBoundingClientRect(),
        amount,
        true
      );
    }
  }, []);

  const moveCoinToWinners = useCallback(async (winners: winnerModel[], delay: number = 0) => {
    await wait(delay);
    const fieldPot = document.querySelector(".field-pot");
    if (fieldPot) {
      for (let winner of winners) {
        const seat = document.getElementsByClassName(`seat-${winner.userId}`)[0];
        if (seat) {
          await coinMove(
            fieldPot.getBoundingClientRect(),
            seat.getBoundingClientRect(),
            winner.amount
          );
        }
      }

      fieldPot.setAttribute('data-amount', '0');
    }
  }, []);

  const getAllCardElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck > .player-card') as NodeListOf<HTMLElement>;
  }, []);

  const getAllDeckElements = useCallback((): NodeListOf<HTMLElement> => {
    return document.querySelectorAll('.card-deck') as NodeListOf<HTMLElement>;
  }, []);

  const getAllCommunityCardElements = useCallback(() => {
    return document.querySelectorAll('.community-card') as NodeListOf<HTMLElement>;
  }, []);

  const hideAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '0';
    }
  }, []);

  const showAllCards = useCallback(() => {
    const cardElements = getAllCardElements();
    for (let i = 0; i < cardElements.length; i++) {
      cardElements[i].style.opacity = '1';
    }
  }, []);

  const resetPlayersCards = useCallback(() => {
    if (!tableRectRef.current) {
      return;
    }

    const cx = tableRectRef.current.x + tableRectRef.current.w / 2;
    const cy = tableRectRef.current.y + tableRectRef.current.h / 2;
    const decks = getAllDeckElements();

    // 모든 카드를 가운데로 모으기
    for (let i = 0; i < decks.length; i++) {
      const deck = decks[i];
      const cards = deck.querySelectorAll('.player-card') as NodeListOf<HTMLElement>;
      cards[0].style.transition = 'none';
      cards[1].style.transition = 'none';
      cards[0].style.opacity = '0';
      cards[1].style.opacity = '0';
      const deckRect = deck.getBoundingClientRect();
      const x = cx - deckRect.x - cards[0].offsetWidth / 2;
      const y = cy - deckRect.y - cards[0].offsetHeight / 2;
      cards[0].style.transform = `translate(${x}px, ${y}px) rotate(-360deg)`;
      cards[1].style.transform = `translate(${x - cards[1].offsetWidth}px, ${y}px) rotate(-360deg)`;
    }
  }, []);

  const dealCardsToPlayers = useCallback(async (players: GamePlayer[]) => {
    const cards0: HTMLElement[] = [];
    const cards1: HTMLElement[] = [];
    for (let i = 0; i < players.length; i++) {
      if (players[i].status !== ROOM_JOIN_STATUS.PLAYING) {
        continue;
      }

      const seat = players[i].seat;
      const cards = document.querySelectorAll(`.card-deck[data-seat="${seat}"] > .player-card`);
      if (cards.length !== 2) {
        throw new Error('card elements of seat ' + seat + ' are not found!');
      }
      cards0.push(cards[0] as HTMLElement);
      cards1.push(cards[1] as HTMLElement);
    }

    // 카드 딜링 애니메이션 (transform 해제 시키기)
    const cards = [...cards0, ...cards1];
    for (let i = 0; i < cards.length; i++) {
      cards[i].style.transition = 'all 200ms linear';
      cards[i].style.opacity = '1';
      cards[i].style.transform = '';
      await new Promise<void>((r) => setTimeout(() => r(), 100));
    }

    {
      // 카드가 혹시라도 이동되지 않는 경우를 대비해 강제적으로 제 위치로 돌려놓는다.
      const cards = getAllCardElements();
      for (let i = 0; i < cards.length; i++) {
        cards[i].style.transition = '';
        cards[i].style.opacity = '1';
        cards[i].style.transform = '';
      }
    }
  }, []);

  useEffect(() => {
    const gameTableElem = document.querySelector('.game-table');
    if (!gameTableElem) {
      return;
    }

    rearrangeLayout();

    gameTableElem.addEventListener('load', rearrangeLayout);
    window.addEventListener('resize', rearrangeLayout);

    return () => {
      gameTableElem.removeEventListener('load', rearrangeLayout);
      window.removeEventListener('resize', rearrangeLayout);
    };
  }, [dealerIndex, maxTableMember, pots, orientation]);

  return {
    tableRect: tableRectRef.current,
    moveCoinToPlayerPot,
    moveCoinToGamePot,
    moveCoinToWinners,
    moveCoinAnte,
    moveCoinRake,
    getAllCardElements,
    getAllDeckElements,
    getAllCommunityCardElements,
    resetPlayersCards,
    hideAllCards,
    showAllCards,
    dealCardsToPlayers,
  };
}

export default useGameLayout;
