import {BET_TYPE, GamePlayer, ROOM_TYPE, RoomInfo} from "../../dataset";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import InGameButton from "../common/InGameButton";
import BetGaugeVertical from "./BetGaugeVertical";
import styled from "styled-components";
import {calcLevel, calcPlayTime} from '../../utils/tournament';
import {parseDatetime} from "../../constants/moment";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import BetGaugeHorizontal from "./BetGaugeHorizontal";
import Flex from "../common/Flex";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media ${MEDIA_DESKTOP} {
    width: 630px;
    left: unset;
    right: 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  justify-content: center;
  padding: 4px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  @media ${MEDIA_DESKTOP} {
    position: relative;
  }
}
  > * {
    flex: 1;
    @media ${MEDIA_DESKTOP} {
      gap: 8px;
    }

`;

const BetGaugeWrapper = styled.div`
  width: fit-content;
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 3;
  padding: 4px;
`;

const RaiseWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
  position: relative;

  > .open-gauge {
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
`;

const WideRaiseWrapper = styled.div`
  position: relative;
  width: 580px;
  height: fit-content;
  display: flex;
  gap: 4px;
  border-radius: 12px;
  padding: 4px;
  color: #FFF;
  >.background {
    position: relative;
    width: 100%;
    height: fit-content;
    aspect-ratio: 764/72;
    z-index: 1;
  }
  >.button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 2;
    left: 10px;
    padding-bottom: 2px;
    > .button {
      background-image: url("/new-image/PC/button_bet.png");
      aspect-ratio: 82/50;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      border-radius: 8px;
      border: 1px solid #242424;
      box-shadow: 0px 2px 4px 0px rgba(16, 16, 18, 0.20);
    }

  }
  > input {
   &:focus {
      outline: none;
   }
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 270px;
    width: 110px;
    border: none;
    border-radius: 8px;
    background: none;
    color: rgb(255, 199, 0);
    font-size: 16px;
    font-weight: 800;
    text-align: right;
    padding: 0px 4px 8px;
    box-shadow: none;
  }
`;

const OpenGaugeButton = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(/new-image/Common/button_seeMore.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    filter: brightness(1.5);
  }

  &:active {
    filter: brightness(0.7);
  }
`;

function ActionButtons(
  {
    onClickTryBet,
    legalActs,
    room,
    myInfo
  }: {
    onClickTryBet: Function,
    legalActs: string[],
    room: RoomInfo,
    myInfo: GamePlayer
  }
): JSX.Element {
  const orientation = useScreenOrientation();
  const [showGauge, setShowGauge] = useState<boolean>(false);
  const [raiseAmount, setRaiseAmount] = useState<number>(0);

  const {pot, raiseQuarter, currentBet} = useMemo<{pot: number, raiseQuarter: number, currentBet: number}>(() => {
    const pot = room.pots[room.pots.length - 1];
    const bets = room.players.reduce((a, b) => a + b.bet, 0);
    const raiseQuarter = Math.floor((pot + bets) * 0.25);
    const currentBet = room.currentBet ?? 0;
    return {pot, raiseQuarter, currentBet};
  }, [room?.pots, room?.players, room?.currentBet]);

  let smallBlind: number, bigBlind: number;
  if (room.type == ROOM_TYPE.RING) {
    smallBlind = room.groupData.blind[0];
    bigBlind = room.groupData.blind[1];
  } else {
    const {
      playTimeSeconds,
      restTimeSeconds
    } = room.groupData.timeStructure;
    let playTime = calcPlayTime(parseDatetime(room.groupData.startedAt), playTimeSeconds, restTimeSeconds);
    let level = calcLevel(playTime, room.groupData.blindStructure);
    smallBlind = room.groupData.blindStructure[level][0];
    bigBlind = room.groupData.blindStructure[level][1];
  }

  const handleChange = useCallback((v: number) => {
    const newValue = Number(v);
    if (newValue > myInfo?.stackSize) {
      setRaiseAmount(myInfo.stackSize);
    } else {
      setRaiseAmount(Math.floor(Number(v)));
    }
  }, [myInfo?.stackSize]);

  const [minRaise, maxRaise] = useMemo<[number, number]>(() => {
    const ret: [number, number] = [currentBet + room.minRaise, myInfo.stackSize]
    if (ret[0] > ret[1]) {
      ret[0] = ret[1];
    }
    return ret;
  }, [currentBet, room?.minRaise, myInfo.stackSize]);

  const handleClickPortion = useCallback((portion: number) => {
    const cap = maxRaise - minRaise;
    setRaiseAmount(minRaise + Math.floor(cap * portion / 100));
  }, [minRaise, maxRaise]);

  useEffect(() => {
    setRaiseAmount(minRaise);
  }, [minRaise]);


  if (orientation === 'landscape') {
    return <Wrapper>
      {
        (legalActs.includes('bet') || legalActs.includes('raise')) && (
          <WideRaiseWrapper>
            <img className="background" src='/new-image/PC/frame_bet.png' />
            <div className='button-wrapper'>
              <div className="button" onClick={() => handleClickPortion(33)}>33%</div>
              <div className="button" onClick={() => handleClickPortion(50)}>50%</div>
              <div className="button" onClick={() => handleClickPortion(75)}>75%</div>
              <div className="button" onClick={() => handleClickPortion(100)}>최대</div>
            </div>
            <input type='number' value={raiseAmount} onChange={(e) => handleChange(+e.target.value)}/>
            <BetGaugeHorizontal
              min={minRaise}
              max={maxRaise}
              value={raiseAmount}
              onChange={handleChange}
            />
          </WideRaiseWrapper>
        )
      }
      <ButtonWrapper>
        <InGameButton flex mode='fold' dark onClick={() => onClickTryBet(BET_TYPE.FOLD)}>폴드</InGameButton>
        {
          legalActs.includes('check') && (
            <InGameButton flex mode='check' onClick={() => onClickTryBet(BET_TYPE.CHECK)}>체크</InGameButton>
          )
        }
        {
          legalActs.includes('call') && (
            <InGameButton flex mode='call' onClick={() => onClickTryBet(BET_TYPE.CALL)}>콜</InGameButton>
          )
        }
        {
          (legalActs.includes('bet')) && (
            <InGameButton flex mode='raise' onClick={() => onClickTryBet(BET_TYPE.BET, raiseAmount)}>레이즈</InGameButton>
          )
        }
        {
          (legalActs.includes('raise')) && (
            <InGameButton flex mode='raise' onClick={() => onClickTryBet(BET_TYPE.RAISE, raiseAmount)}>레이즈</InGameButton>
          )
        }
      </ButtonWrapper>
    </Wrapper>;
  } else {
    if (showGauge) {
      return <BetGaugeWrapper>
        <BetGaugeVertical
          bb={bigBlind}
          pot={pot}
          min={minRaise}
          max={maxRaise}
          onConfirm={(amount) => onClickTryBet(BET_TYPE.RAISE, amount)}
          onCancel={() => setShowGauge(false)}
        />
      </BetGaugeWrapper>;
    }

    const AllInButton = () => {
      return <InGameButton
        flex
        mode={`raise`}
        subTitle={`ALL IN`}
        raise
        disabled={false}
        onClick={() => onClickTryBet(BET_TYPE.ALLIN, myInfo.stackSize)}
      >
        {myInfo.stackSize}
      </InGameButton>;
    }

    return <Wrapper>
      <ButtonWrapper>
        <InGameButton flex mode={'fold'} dark onClick={() => onClickTryBet(BET_TYPE.FOLD)}>폴드</InGameButton>
        {
          legalActs.includes("check") && (
            <InGameButton flex mode={'check'} onClick={() => onClickTryBet(BET_TYPE.CHECK)}>체크</InGameButton>
          )
        }
        {
          legalActs.includes("call") && (
            <InGameButton flex mode={'call'} onClick={() => onClickTryBet(BET_TYPE.CALL)}>콜</InGameButton>
          )
        }
        {legalActs.includes("bet") && myInfo.stackSize > minRaise ? <RaiseWrapper>
          <OpenGaugeButton className="open-gauge" onClick={() => setShowGauge(true)}/>
          <InGameButton flex mode={'raise'} subTitle={"3bb"} bet disabled={myInfo.stackSize < currentBet + bigBlind * 3}
                        onClick={() => onClickTryBet(BET_TYPE.BET, currentBet + bigBlind * 3)}>{currentBet + bigBlind * 3}</InGameButton>
          <InGameButton flex mode={'raise'} subTitle={"2bb"} bet disabled={myInfo.stackSize < currentBet + bigBlind * 2}
                        onClick={() => onClickTryBet(BET_TYPE.BET, currentBet + bigBlind * 2)}>{currentBet + bigBlind * 2}</InGameButton>
          <InGameButton flex mode={'raise'} subTitle={"1bb"} bet disabled={myInfo.stackSize < currentBet + bigBlind}
                        onClick={() => onClickTryBet(BET_TYPE.BET, currentBet + bigBlind)}>{currentBet + bigBlind}</InGameButton>
        </RaiseWrapper> : <></>}
        {legalActs.includes("raise") && myInfo.stackSize > minRaise && raiseQuarter >= room.minRaise ?
          <RaiseWrapper>
            <OpenGaugeButton className="open-gauge" onClick={() => setShowGauge(true)}/>
            {myInfo.stackSize < currentBet + raiseQuarter * 4 ? <AllInButton/> :
              <InGameButton flex mode={'raise'} subTitle={"100%"} disabled={myInfo.stackSize < currentBet + raiseQuarter * 4} raise
                            onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + raiseQuarter * 4)}>{currentBet + raiseQuarter * 4}</InGameButton>}
            {myInfo.stackSize < currentBet + raiseQuarter * 2 ? <AllInButton/> :
              <InGameButton flex mode={'raise'} subTitle={"50%"} disabled={myInfo.stackSize < currentBet + raiseQuarter * 2} raise
                            onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + raiseQuarter * 2)}>{currentBet + raiseQuarter * 2}</InGameButton>}
            <InGameButton flex mode={'raise'} subTitle={"25%"} disabled={myInfo.stackSize < currentBet + raiseQuarter} raise
                          onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + raiseQuarter)}>{currentBet + raiseQuarter}</InGameButton>
          </RaiseWrapper> : <></>}
        {legalActs.includes("raise") && myInfo.stackSize > minRaise && raiseQuarter < room.minRaise ?
          <RaiseWrapper>
            <OpenGaugeButton className="open-gauge" onClick={() => setShowGauge(true)}/>
            {myInfo.stackSize < currentBet + Math.floor(room.minRaise * 4) ? <AllInButton/> :
              <InGameButton flex mode={'raise'} subTitle={`${((currentBet + Math.floor(room.minRaise * 4)) / bigBlind).toFixed(1)}bb`} raise
                            disabled={myInfo.stackSize < currentBet + Math.floor(room.minRaise * 4)}
                            onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + Math.floor(room.minRaise * 4))}>{currentBet + Math.floor(room.minRaise * 4)}</InGameButton>}
            {myInfo.stackSize < currentBet + Math.floor(room.minRaise * 2) ? <AllInButton/> :
              <InGameButton flex mode={'raise'} subTitle={`${((currentBet + Math.floor(room.minRaise * 2)) / bigBlind).toFixed(1)}bb`} raise
                            disabled={myInfo.stackSize < currentBet + Math.floor(room.minRaise * 2)}
                            onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + Math.floor(room.minRaise * 2))}>{currentBet + Math.floor(room.minRaise * 2)}</InGameButton>}
            <InGameButton flex mode={'raise'} subTitle={`${((currentBet + Math.floor(room.minRaise * 1)) / bigBlind).toFixed(1)}bb`} raise
                          disabled={myInfo.stackSize < currentBet + Math.floor(room.minRaise * 1)}
                          onClick={() => onClickTryBet(BET_TYPE.RAISE, currentBet + Math.floor(room.minRaise * 1))}>{currentBet + Math.floor(room.minRaise)}</InGameButton>
          </RaiseWrapper> : <></>}
        {legalActs.includes("raise") && myInfo.stackSize < minRaise ? <RaiseWrapper>
          {<AllInButton/>}
        </RaiseWrapper> : <></>}
      </ButtonWrapper>
    </Wrapper>;
  }
}

export default ActionButtons;
