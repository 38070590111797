import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import produce from 'immer';
import moment from "moment";
import styled, {keyframes} from 'styled-components';
import {
  USER_TOKEN,
  client,
  requestBuyin,
  requestLeaveRoom,
  requestMyCards,
  requestMyStatusInRoom,
  requestRoomInfo,
  requestSetBlindWait,
  requestTryBet,
  useSocketIsConnect
} from '../api';
import {useHistory} from 'react-router-dom';
import {setAckListener as setStartGameShuffleListener} from "../api/from_server_game_startGameShuffle";
import {setAckListener as setRequestBetListener} from "../api/from_server_game_requestBet";
import {setAckListener as setGameWinnerListener} from "../api/from_server_game_gameWinner";
import {setAckListener as setChangeRoundListener} from '../api/from_server_game_changeRound';
import {setAckListener as setEmitAfterBetListener} from "../api/from_server_game_emitAfterBet";
import {setAckListener as setOpenCommunityCardsListener} from "../api/from_server_game_openCommunityCards";
import {setAckListener as setLeaveJoinRoomSocketListener} from "../api/from_server_game_leaveJoinRoomSocket";
import {setAckListener as setRefreshPlayersInfoListener} from "../api/from_server_game_refreshPlayersInfo";
import {setAckListener as setAllInShowdownListener} from "../api/from_server_game_allInShowdown";
import {setAckListener as setTableCleanUpListener} from "../api/from_server_game_tableCleanUp";
import {setAckListener as setCollectAnteListener} from "../api/from_server_game_collectAnte";
import {setAckListener as setCollectRakeListener} from "../api/from_server_game_collectRake";
import {
  BET_TYPE,
  emitAfterBetModel,
  GamePlayer,
  PlayersBettings,
  RAKE_TYPE,
  requestBetModel,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  winnerModel
} from '../dataset';
import InGameButton from "../components/common/InGameButton";
import ModalContainer from "../components/common/ModalContainer";
import BuyInModal from "../components/BuyInModal";
import useGameLayout from "../hooks/useGameLayout";
import useLoadGame from "../hooks/useLoadGame";
import Player from "../components/game/Player";
import useDialog from "../hooks/useDialog";
import {closeWindow, getKoreanNumber, wait} from "../utils/common";
import PokerCard from "../components/game/PokerCard";
import FieldPots from "../components/game/FieldPots";
import PlayerPot from "../components/game/PlayerPot";
import ActionButtons from "../components/game/ActionButtons";
import ProfileModal from "../components/ProfileModal";
import GameHistory from "../components/game/GameHistory";
import RightDrawer from "../components/common/Drawer";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {parseDatetime} from "../constants/moment";
import {calcElapsedForRound, determineRestTime} from "../utils/tournament";
import {useRecoilValue} from 'recoil';
import {myInfoState} from "../recoil/MyInfo";
import Flex from "../components/common/Flex";
import StatusBoard from "../components/game/StatusBoard";
import useWinningRate from "../hooks/useWinningRate";
import {connectionState} from "../recoil/Connection";

const GameBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  opacity: 1;
  background: none;

  > div {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    opacity: 0.02;
    background: none;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  padding: 20px;
  z-index: 1;
  gap: 8px;

  @media ${MEDIA_DESKTOP} {
    padding: 30px 40px;
  }

  #roomInfo {
    > div {
      margin-left: 16px;
      margin-top: 0;
      position: initial;
      top: 0;
      left: 0;
    }
  }
`;

const LeaveButton = styled.button`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  font-size: 15px;
  border-radius: 20px;
  border-width: 1px;
  border-color: rgb(180, 180, 180);
  border-style: solid;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`;

const OptionButton = styled.button`
  color: white;
  width: 42px;
  height: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 10px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  cursor: pointer;
  position: relative;

  @media ${MEDIA_DESKTOP} {
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  .circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgb(180, 180, 180);
  }

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const PrizeWrapper = styled.div`
  display: none;
  @media ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    gap: 12px;
  }
  margin: 20px 24px;
  padding: 4px 4px 6px 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-image: -moz-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(31, 32, 33) 0%, rgb(41, 42, 42) 59%, rgb(47, 49, 48) 61%, rgb(53, 55, 53) 71%, rgb(67, 69, 69) 92%, rgb(53, 55, 53) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
  color: rgb(255, 199, 0);

  > img {
    width: 100px;
  }

  > .prize {
    text-align: center;
    width: 220px;
    font-size: 20px;
    font-weight: 800;
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
    background-image: -moz-linear-gradient(90deg, rgb(20, 21, 22) 3%, rgb(16, 17, 17) 98%);
    background-image: -webkit-linear-gradient(90deg, rgb(20, 21, 22) 3%, rgb(16, 17, 17) 98%);
    background-image: -ms-linear-gradient(90deg, rgb(20, 21, 22) 3%, rgb(16, 17, 17) 98%);
  }

`
const RoomName = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 10px;
`;

const FlopCardStartAnimation = keyframes`
  from {
    transform: scale(2);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FlopCardOpenAnimation = keyframes`
  to {
    transform: translateX(0);
  }
`;

const CommunityCards = styled.div<{
  noAnimation: boolean
}>`
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
  position: absolute;

  &:not([data-cards="0"]) {
    animation: ${FlopCardStartAnimation} 0.2s linear;
    ${p => p.noAnimation && `
      animation-duration: 0s !important;
    `};
  }

  > * {
    width: 42px;
    height: 56px;
    opacity: 0;

    @media ${MEDIA_DESKTOP} {
      width: 85px;
      height: fit-content;
      aspect-ratio: 60/80;
    }

    &[data-open="true"] {
      opacity: 1;
      animation-delay: 0.3s;
      animation-duration: 0.2s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;

      ${p => p.noAnimation && `
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      `};

      &:nth-of-type(1) {

      }

      &:nth-of-type(2) {
        transform: translateX(calc(-100% - 2px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(3) {
        transform: translateX(calc(-200% - 4px));
        animation-name: ${FlopCardOpenAnimation};
      }

      &:nth-of-type(4) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }

      &:nth-of-type(5) {
        animation: ${FlopCardStartAnimation} 0.2s linear 0s;
      }
    }
  }
`;

const DealerButton = styled.div`
  width: 22px;
  aspect-ratio: 100/70;
  background-image: url(/new-image/chip/dealerChip_04.png);
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  @media ${MEDIA_DESKTOP} {
    width: 40px;
  }
`;

const CoinMoveWrapper = styled.div`
  .move-coin {
    position: absolute;
    left: 3px;
    top: 15px;
    width: 12px;
    height: 12px;
    @media ${MEDIA_DESKTOP} {
      top: 20px;
      width: 32px;
      height: 32px;
    }
    transition: all 0.3s ease-out;
    background-size: contain;
    transform: rotateX(30deg);
    opacity: 1;
    z-index: 1;
  }
`;

const GameTable = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  padding: 20px;
  background: url(/new-image/Common/bg_tile.png) repeat center;
  background-size: 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > img.game-table.landscape {
    position: relative;
    width: 80%;
    pointer-events: none;
    user-select: none;
    aspect-ratio: 1561/729;
  }

  > img.game-table.vertical {
    position: relative;
    object-fit: contain;
    width: 130%;
    max-height: 80svh;
    aspect-ratio: 1286/1654;
    pointer-events: none;
    user-select: none;
  }

  > img.game-table-light.vertical {
    position: absolute;
    z-index: 0;
    object-fit: contain;
    width: 160%;
    max-height: 90svh;
    height: fit-content;
    pointer-events: none;
    user-select: none;
  }

  > div.table-hole {
    position: absolute;
    top: 0;
    left: 0;

    > img {
      transform: scale(0, 1);
      transition: transform 0.2s ease-in-out;
    }
  }
`;

const JoinButtonWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;

  > div {
    width: auto;
  }

  @media ${MEDIA_DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 20px;
    padding: 4px 8px;
  }
`;

type WinnerCards = {
  [userId: number]: number[]
}
type PlayerAct = {
  [userId: number]: number
}

const RestTimePopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 480px;
  background: linear-gradient(90deg, rgba(24, 26, 29, 0.00) 0%, rgba(24, 26, 29, 0.50) 17.71%, rgba(24, 26, 29, 0.50) 82.32%, rgba(24, 26, 29, 0.00) 100%);
  padding: 8px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (): JSX.Element => {
  const history = useHistory();
  const {
    openDialog,
    closeDialog
  } = useDialog();
  const [conn] = useSocketIsConnect();
  const {
    roomId,
    room,
    players,
    myInfo,
    blind,
    updatePlayer,
    updateStackSizes,
  } = useLoadGame();

  const myProfileInfo = useRecoilValue(myInfoState);
  const connected = useRecoilValue(connectionState);

  const initialized = useRef<boolean>(false);
  const isPlaying = useRef<boolean>(false);
  const [noRoundAnim, setNoRoundAnim] = useState<boolean>(true);
  const [myCards, setMyCards] = useState<number[]>([]);
  const [betData, setBetData] = useState<requestBetModel | null>(null);
  const [communityCards, setCommunityCards] = useState<number[]>([]);
  const [winners, setWinners] = useState<winnerModel[]>([]);
  const [winnerCards, setWinnerCards] = useState<WinnerCards>({});
  const [pots, setPots] = useState<number[]>([]);
  const [buyInSeat, setBuyInSeat] = useState<number>(-1);
  const [sentAct, setSentAct] = useState<boolean>(false);
  const [playersBetting, setPlayersBetting] = useState<PlayersBettings[]>([]);
  const [playersAct, setPlayersAct] = useState<PlayerAct>({});
  const [timerText, setTimerText] = useState<string>('');
  const [isRestTime, setRestTime] = useState<boolean>(false);
  const [myRank, setMyRank] = useState<number>(0);
  const [totalMember, setTotalMember] = useState<number>(0);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [profileUserId, setProfileUserId] = useState<number>(-1);
  const [showOptionModal, setShowOptionModal] = useState<boolean>(false);
  const orientation = useScreenOrientation();

  const gameLayout = useGameLayout({
    dealerIndex: players.findIndex(p => p?.seat === room?.buttonPosition),
    maxTableMember: room?.groupData.maxTableMember,
    pots: pots,
  });

  const calculator = useWinningRate();

  const showActions = useMemo<boolean>(() => {
    if (sentAct) {
      return false;
    } else if (betData?.userId !== myInfo?.userId) {
      return false;
    } else if (!betData?.legalAct) {
      return false;
    }

    return true;
  }, [betData?.userId, myInfo?.userId, sentAct]);

  const showJoinButtonRing = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.RING && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const showJoinButtonTournament = useMemo<boolean>(() => {
    return room?.type === ROOM_TYPE.TOURNAMENT && myInfo?.status === ROOM_JOIN_STATUS.BUYIN_READY && myInfo?.waitGame;
  }, [room, myInfo?.status, myInfo?.waitGame]);

  const isRetired = useMemo<boolean>(() => {
    if (myInfo?.blindWait === true) {
      if (room?.groupData.rakeType == RAKE_TYPE.HAND_RAKE && myInfo?.stackSize <= room?.groupData.rake) {
        return true;
      } else if (room?.groupData.rakeType != RAKE_TYPE.HAND_RAKE && myInfo?.stackSize == 0) {
        return true;
      }
    }
    return false;
  }, [room?.groupData, myInfo?.blindWait, myInfo?.stackSize]);

  const resetGame = useCallback(() => {
    setMyCards([]);
    setCommunityCards([]);
    setBetData(null);
    setWinners([]);
    setWinnerCards([]);
    setPots([]);
    setPlayersBetting([]);
    setSentAct(false);
    setPlayersAct({});
    calculator.resetWinningRates();

    // 카드 요소 전부 감추기
    gameLayout.hideAllCards();
  }, []);

  const refreshGame = useCallback(() => {
    if (roomId >= 0) {
      (async () => {
        initialized.current = false;
        await requestRoomInfo(roomId);
        await requestMyStatusInRoom(roomId);
        requestMyCards(roomId).then(({cards}) => {
          if (cards) {
            setMyCards(cards);
          }
        });
      })();
    }
  }, [roomId]);

  useEffect(() => {
    function onVisibilityChange(e: any) {
      if (document.visibilityState === 'visible') {
        refreshGame();
      }
    }

    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => window.removeEventListener('visibilitychange', onVisibilityChange);
  }, [refreshGame]);

  useEffect(() => {
    resetGame();
  }, [roomId]);

  useEffect(() => {
    if (connected) {
      refreshGame();
    }
  }, [connected, refreshGame]);

  useEffect(() => {
    if (!sessionStorage.getItem(USER_TOKEN)) {
      alert("잘못된 접근입니다. 다시 접속해주세요.");
      window.location.href = process.env.REACT_APP_HOST_URL as string;
    }
  }, [])

  useEffect(() => {
    if (!connected && room?.groupId) {
      const dialogId = openDialog({
        title: '재접속',
        text: '서버와의 연결이 끊겨 재접속 중입니다...',
        confirmText: '연결 취소',
        disableBackdropClick: true,
        onConfirm: () => {
          const platform = orientation === 'landscape' ? 'pc' : 'mobile'
          window.location.href = `${process.env.REACT_APP_HOST_URL}/${platform}/game/gameInfo?groupId=${room?.groupId}`
        }
      });

      client.once('connect', () => {
        closeDialog(dialogId);
      });
    }
  }, [connected, room?.groupId])

  // 진행중인 게임 데이터 구성
  useEffect(() => {
    if (!room || initialized.current) {
      return;
    }

    const acts: PlayerAct = {};
    const betting: PlayersBettings[] = [];
    for (let player of room.players) {
      acts[player.userId] = player.lastAction;
      betting.push({
        id: player.userId,
        bet: player.bet,
        ante: player.ante,
        rake: player.rake,
        stackSize: player.stackSize,
        folded: player.status === ROOM_JOIN_STATUS.FOLD
      });
    }
    setPlayersAct(acts);
    setPlayersBetting(betting);
    setPots(room.pots);
    setCommunityCards(room.cards || []);

    if (room.roomStatus === ROOM_STATUS.INGAME) {
      gameLayout.showAllCards();
    }

    if (room.type === ROOM_TYPE.TOURNAMENT) {
      // 휴식시간 세팅
      const startedAt = parseDatetime(room.groupData.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = room.groupData.timeStructure;
      if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
        setRestTime(true);
        gameLayout.hideAllCards();
      }
    }

    initialized.current = true;
  }, [room]);

  useEffect(() => {
    setStartGameShuffleListener(async (data: {
      roomId: number,
      userId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (isRestTime) {
        setRestTime(false);
      }

      isPlaying.current = true;

      // 모든 카드 가운데로 모으기
      gameLayout.resetPlayersCards();

      // 딜러버튼 위치부터 시계방향으로 배열 만들어서 카드 딜링
      let arr = players.filter(x => x !== undefined) as GamePlayer[];
      const startIndex = arr.findIndex(x => x?.userId === data?.userId);
      arr = arr.splice(startIndex).concat(arr);
      await gameLayout.dealCardsToPlayers(arr);

      // SB/BB
      const playersBetting: PlayersBettings[] = [];
      for (let player of players) {
        if (player && player.status === ROOM_JOIN_STATUS.PLAYING && player.bet > 0) {
          await gameLayout.moveCoinToPlayerPot(player.userId, player.bet, 100);
          playersBetting.push({
            id: player.userId,
            bet: player.bet,
            ante: player.ante,
            rake: player.rake,
            folded: false,
            stackSize: player.stackSize
          });
          setPlayersBetting(playersBetting);
        }
      }

      // 내 카드 세팅
      if (myInfo && myInfo.seat >= 0) {
        const {cards} = await requestMyCards(roomId);
        setMyCards(cards);
      }
    });
    setRequestBetListener(async (data: requestBetModel) => {
      if (roomId != data.roomId) {
        return;
      }
      if (!isPlaying.current) {
        isPlaying.current = true;
      }

      setBetData(data);

      if (sentAct && data.userId !== myInfo?.userId) {
        setSentAct(false);
      }
    });
    setEmitAfterBetListener(async (data: {
      model: emitAfterBetModel,
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const model = data.model;

      if (model.lastBetStatus) {
        const {
          userId,
          type,
          bet
        } = model.lastBetStatus;

        setPlayersAct(produce((d) => {
          d[userId] = type;
        }));

        if (bet > 0) {
          await gameLayout.moveCoinToPlayerPot(userId, bet, 100);
        }

        setBetData(null);
      }

      if (model.playerBettings) {
        setPlayersBetting(model.playerBettings);

        // 스택사이즈 업데이트
        await updateStackSizes(model.playerBettings);
      }

      // 올인인 경우 팟 반영
      if (model.isAllIn && model.pots) {
        setPots(model.pots.map(x => x.amount));
        await gameLayout.moveCoinToGamePot();
        setPlayersBetting([]);
      }
    });
    setChangeRoundListener(async (data: {
      roomId: number
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        setNoRoundAnim(false);

        const prevTotalPot = pots.reduce((a, v) => a + v, 0);
        const newTotalPot = room.pots.reduce((a, v) => a + v, 0);
        if (newTotalPot > prevTotalPot) {
          setPots(room.pots || []);
          await gameLayout.moveCoinToGamePot();
        }

        // 폴드 외 액션 삭제
        setPlayersAct(produce((d) => {
          for (let userId in d) {
            if (d[userId] !== BET_TYPE.FOLD) {
              delete d[userId];
            }
          }
        }));
        setPlayersBetting([]);
        await wait(500);
        setCommunityCards(room.cards || []);
      }
    });
    setAllInShowdownListener(async (data: {
      roomId: number,
      wholeCards: {
        userId: number,
        holeCards: number[]
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const winnerCards: WinnerCards = {};
      for (let {
        userId,
        holeCards
      } of data.wholeCards) {
        winnerCards[userId] = holeCards;
        calculator.addPlayer(userId, holeCards);
      }

      setWinnerCards(winnerCards || {});
      calculator.updateWinningRate();
    });
    setOpenCommunityCardsListener(async (data: {
      roomId: number;
      cards: number[];
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      setCommunityCards(data.cards);
      await calculator.setCommunityCard(data.cards);

      setTimeout(() => {
        calculator.updateWinningRate();
      }, 1000);
    });
    setGameWinnerListener(async (data: {
      roomId: number;
      winners: winnerModel[];
      prize: number;
      wholeCards: {
        [key: number]: number[]
      };
      tournamentRankingList: {
        userId: number,
        stackSize: number,
        ranking: number
      }[];
      totalMemberCount: number;
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (room) {
        // 위너 설정
        setWinners(data.winners);
        setWinnerCards(data.wholeCards || {});
        calculator.resetWinningRates();
        await gameLayout.moveCoinToWinners(data.winners);
        setPots([]);

        // 토너먼트 처리
        if (room.type === ROOM_TYPE.TOURNAMENT) {
          // 랭킹 세팅
          if (data.tournamentRankingList) {
            const myRanking = data.tournamentRankingList.find(x => x.userId === myInfo?.userId);
            if (myRanking) {
              setMyRank(myRanking.ranking);
              setTotalMember(data.totalMemberCount);
            }
          }

          // 휴식시간 세팅
          const startedAt = parseDatetime(room.groupData.startedAt);
          const {
            playTimeSeconds,
            restTimeSeconds
          } = room.groupData.timeStructure;
          if (determineRestTime(startedAt, playTimeSeconds, restTimeSeconds)) {
            setRestTime(true);
          }
        }
      }
    });
    setTableCleanUpListener(async () => {
      resetGame();
      isPlaying.current = false;
    });
    setLeaveJoinRoomSocketListener(async (data: any) => {
      if (roomId != data.roomId) {
        return;
      }

      const platform = orientation === 'landscape' ? 'pc' : 'mobile'
      const gameDetailUrl = `${process.env.REACT_APP_HOST_URL}/${platform}/game/gameInfo?groupId=${room?.groupId}`
      if (data.reason == -1) {
        openDialog({
          title: '안내',
          text: '게임룸이 닫혀 대기실로 이동되었습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            window.location.href = gameDetailUrl
          }
        });
      } else if (data.reason == -2) {
        openDialog({
          title: '안내',
          text: '장시간 입력이 없어 대기실로 이동되었습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            window.location.href = gameDetailUrl
          }
        });
      } else if (data.reason == -4) {
        openDialog({
          title: '안내',
          text: '칩을 모두 잃어 대기실로 이동되었습니다.',
          disableBackdropClick: true,
          onConfirm: () => {
            window.location.href = gameDetailUrl
          }
        });
        return;
      }
    });
    setRefreshPlayersInfoListener(async (data: {
      roomId: number,
      players: GamePlayer[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      for (let seat in data.players) {
        const player = data.players[seat];
        if (isPlaying.current && player) {
          player.status = ROOM_JOIN_STATUS.BUYIN_READY;
        }
        await updatePlayer(Number(seat), player);
      }

      if (room?.type !== ROOM_TYPE.TOURNAMENT || !isRestTime) {
        gameLayout.showAllCards();
      }
    });
    setCollectAnteListener(async (data: {
      roomId: number,
      antePlayers: {
        userId: number,
        ante: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      if (data.antePlayers) {
        for (let {
          userId,
          ante
        } of data.antePlayers) {
          setPots(produce(d => {
            d[0] = (d[0] || 0) + ante;
          }));
          await gameLayout.moveCoinAnte(userId, ante);
        }
      }
    });
    setCollectRakeListener(async (data: {
      roomId: number,
      rakePlayers: {
        userId: number,
        rake: number
      }[]
    }) => {
      if (roomId != data.roomId) {
        return;
      }

      const hole = document.querySelector('.table-hole > img') as HTMLElement;
      if (hole) {
        await wait(700);
        hole.style.transform = 'scale(1, 1)'
      }

      if (data.rakePlayers) {
        for (let {
          userId,
          rake
        } of data.rakePlayers) {
          await gameLayout.moveCoinRake(userId, rake);
        }
      }

      if (hole) {
        await wait(700);
        hole.style.transform = 'scale(0, 1)'
      }
    });
    return () => {
      setStartGameShuffleListener(null);
      setRequestBetListener(null);
      setEmitAfterBetListener(null);
      setChangeRoundListener(null);
      setAllInShowdownListener(null);
      setOpenCommunityCardsListener(null);
      setGameWinnerListener(null);
      setTableCleanUpListener(null);
      setLeaveJoinRoomSocketListener(null);
      setRefreshPlayersInfoListener(null);
      setCollectAnteListener(null);
      setCollectRakeListener(null);
    };
  });

  // 토너먼트 남은시간 타이머 계산
  useEffect(() => {
    if (!room || room.type !== ROOM_TYPE.TOURNAMENT) {
      return;
    }

    const startedAt = parseDatetime(room.groupData.startedAt);
    const {
      playTimeSeconds,
      restTimeSeconds
    } = room.groupData.timeStructure;

    const interval = setInterval(() => {
      let leftSec = 0;

      const elapsedForRound = calcElapsedForRound(startedAt, playTimeSeconds, restTimeSeconds);
      if (elapsedForRound > 0) {
        if (elapsedForRound >= playTimeSeconds) {
          // 현재 휴식 시간. 다음 라운드까지 남은 시간
          if (isRestTime) {
            leftSec = playTimeSeconds + restTimeSeconds - elapsedForRound;
          }
        } else {
          // 현재 라운드 진행 중. 다음 휴식 시간까지 남은 시간
          if (!isRestTime) {
            leftSec = playTimeSeconds - elapsedForRound;
          }
        }
      } else {
        // 토너먼트 시작 전. 시작까지 남은 시간
        leftSec = -elapsedForRound;
      }

      if (leftSec >= 0) {
        setTimerText(moment.unix(leftSec).format('mm분 ss초 남음'));
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [room, isRestTime]);

  const handleLeave = useCallback(async () => {
    let data = await requestLeaveRoom(roomId);
    if (data.result) {
      const platform = orientation === 'landscape' ? 'pc' : 'mobile'
      window.location.href = `${process.env.REACT_APP_HOST_URL}/${platform}/game/gameInfo?groupId=${room?.groupId}`
    }
  }, [room, roomId]);

  const handleShowOption = useCallback(() => {
    setShowOptionModal(true);
  }, []);

  const handleToggleBlindWait = useCallback(async () => {
    await requestSetBlindWait(roomId, !myInfo?.blindWait);
  }, [myInfo?.blindWait]);

  const handleSitDown = useCallback(async (s: number) => {
    if (myInfo?.status !== ROOM_JOIN_STATUS.PLAYING) {
      setBuyInSeat(s);
    }
  }, [myInfo?.status]);

  const handleBuyIn = useCallback(async (amount: number) => {
    const seat = buyInSeat;
    setBuyInSeat(-1);
    let data = await requestBuyin(roomId, amount, seat);
    if (data.result == -1) {
      openDialog({
        title: '안내',
        text: '알수없는 오류로 바이인을 실패했습니다.'
      });
    } else if (data.result == -2) {
      openDialog({
        title: '안내',
        text: '보유 머니가 부족하여 바이인을 실패했습니다.'
      });
    } else if (data.result == -3) {
      openDialog({
        title: '안내',
        text: '이미 해당 자리에 누군가 먼저 앉아서 바이인을 실패하였습니다.'
      });
    }
  }, [buyInSeat]);

  const handleRejoinGame = useCallback(async () => {
    if (!isRetired || !myInfo) {
      return;
    }

    if (room?.type === ROOM_TYPE.RING) {
      setBuyInSeat(myInfo.seat);
    } else if (room?.type === ROOM_TYPE.TOURNAMENT) {
      // 0원되면 그냥 쫒겨남
      // openDialog({
      //   title: '바이인 안내',
      //   text: `리바이인 시 ${room.groupData.rebuyinChip.toLocaleString()} 을 얻고, ${room.groupData.buyinPrice} 를 지출하게 됩니다.<br/>현재 머니 : ${myProfileInfo?.money}`,
      //   confirm: true,
      //   confirmText: '리바인',
      //   cancelText: '취소',
      //   onConfirm: async () => {
      //     await requestRebuyinTournament(room.groupId);
      //   }
      // });
    }
  }, [room, myInfo, isRetired, myProfileInfo]);

  const handleTryBet = useCallback(async (type: BET_TYPE, betAmount: number) => {
    await requestTryBet(roomId, type, betAmount);
    setBetData(null);
  }, [roomId]);

  const handleOpenProfile = useCallback((userId: number) => {
    setProfileUserId(userId);
  }, []);

  if (!room) {
    return <></>;
  }

  const StatusBoardComponent = <StatusBoard
    roomType={room.type}
    SB={blind.small}
    BB={blind.big}
    ante={blind.ante}
    rakeType={room.groupData.rakeType}
    rake={room.groupData.rake}
    myRank={myRank}
    totalMember={totalMember}
    straddle={room.groupData.isStraddle}
    timerText={timerText}
  />;

  return <>
    <GameBackground>
      <div/>
    </GameBackground>
    <Wrapper>
      <RoomName>[{room.gameName}]</RoomName>
      <Header>
        <LeaveButton onClick={handleLeave}>나가기</LeaveButton>
        <Flex>
          {
            orientation === 'landscape' && (
              StatusBoardComponent
            )
          }
        </Flex>
        <OptionButton onClick={() => setShowHistory(true)}>
          <div className='circle'/>
          <div className='circle'/>
          <div className='circle'/>
        </OptionButton>
      </Header>
      <GameTable>
        {
          orientation === 'portrait' &&
          <img className='game-table-light vertical' src="/new-image/Mobile/table-light.png"/>
        }

        <img
          className={"game-table " + (orientation === 'portrait' ? 'vertical' : 'landscape')}
          src={`/new-image/${orientation === 'portrait' ? 'Mobile/table_vertical.png' : 'PC/table_horizontal.png'}`}
        />
        <div className="table-hole">
          <img src="/images/table_hole.svg"/>
        </div>
        {
          players.map((p, i) => {
            const userId = p?.userId ?? -1;
            const isMe = userId === myInfo?.userId;
            const disabled = !p && !!myInfo && myInfo?.status !== ROOM_JOIN_STATUS.OBSERVE;
            const isBetNow = betData?.userId == userId;
            const winningRate = calculator.winningRates.find(x => x.userId === userId);

            const cards = [];
            if (isMe && Array.isArray(myCards)) {
              cards.push(...myCards);
            } else if (winnerCards[userId]) {
              cards.push(...winnerCards[userId]);
            }

            return <Player
              key={i}
              idx={i}
              player={p}
              BB={blind.big}
              act={playersAct[userId] || p?.lastAction}
              me={isMe}
              betNow={isBetNow}
              disabled={disabled}
              winners={winners}
              rate={winningRate?.rate}
              leftSec={isBetNow ? betData?.leftSec : undefined}
              communityCards={communityCards}
              cards={cards}
              onClickSeat={handleSitDown}
              onClickProfile={handleOpenProfile}
            />;
          })
        }
        {
          Array.from({length: room.groupData.maxTableMember}).map((v, i) => {
            const p = players[i];
            const userId = p?.userId ?? -1;
            let amount = 0;
            if (p && playersBetting) {
              const playerBetting = playersBetting.find(q => q.id == userId);
              if (playerBetting) {
                amount = playerBetting.bet;
              }
            }
            return <PlayerPot key={i} userId={userId} amount={amount}/>;
          })
        }
        <FieldPots pots={pots} BB={blind.big}>
          {
            orientation === 'portrait' && (
              StatusBoardComponent
            )
          }
        </FieldPots>
        <CommunityCards className="community-cards-wrapper" data-cards={communityCards.length}
                        noAnimation={noRoundAnim}>
          {
            Array.from({length: 5}).map((_, i) => {
              const showCard = communityCards[i] >= 0;

              if (showCard) {
                return <PokerCard
                  key={i}
                  className="community-card"
                  data-open={showCard}
                  card={communityCards[i]}
                  flip
                  delay={noRoundAnim ? -1 : 1000}
                />;
              } else {
                return <div/>;
              }
            })
          }
        </CommunityCards>
        <DealerButton className="dealer-button"/>
        <CoinMoveWrapper className="coin-move-wrapper"/>
      </GameTable>
      {
        showActions && (
          <ActionButtons onClickTryBet={handleTryBet} myInfo={myInfo!} room={room} legalActs={betData!.legalAct}/>
        )
      }
      <PrizeWrapper>
        <img src='/new-image/Common/text_prize.png'/>
        <span className='prize'>{getKoreanNumber(room.groupData.prize.reduce((a:number, v:number) => a + v, 0))}원</span>
      </PrizeWrapper>
      {
        showJoinButtonRing && (
          <JoinButtonWrapper>
            {
              isRetired ? (
                <InGameButton joinGame={true} onClick={handleRejoinGame}>
                  게임 참여하기
                </InGameButton>
              ) : (
                <InGameButton joinGame={false} checked={myInfo?.blindWait} onChecked={handleToggleBlindWait}>
                  블라인드 대기
                </InGameButton>
              )
            }
          </JoinButtonWrapper>
        )
      }
      {
        showJoinButtonTournament && (
          <JoinButtonWrapper>
            {
              // isRetired ? (
              //   <InGameButton onClick={handleRejoinGame}>
              //     게임 참여하기
              //   </InGameButton>
              // ) : null
            }
          </JoinButtonWrapper>
        )
      }
      {
        room.type === ROOM_TYPE.RING && (
          <ModalContainer show={buyInSeat !== -1} onBackdropClick={() => setBuyInSeat(-1)}>
            <BuyInModal
              bigBlind={blind.big}
              minBuyIn={room.groupData.minBuyin}
              maxBuyIn={room.groupData.maxBuyin}
              onClickBuyIn={handleBuyIn}
            />
          </ModalContainer>
        )
      }
      {
        isRestTime && (
          <RestTimePopup>쉬는 시간입니다. 남은 시간 안에 착석해주세요.</RestTimePopup>
        )
      }
      <RightDrawer
        opened={showHistory}
        onOpen={() => setShowHistory(true)}
        onClose={() => setShowHistory(false)}
      >
        <GameHistory
          roomId={roomId}
          handNumber={room?.handNumber}
          maxTableMember={room?.groupData.maxTableMember}
          onClose={() => setShowHistory(false)}
        />
      </RightDrawer>
      <ModalContainer show={profileUserId !== -1} onBackdropClick={() => setProfileUserId(-1)}>
        <ProfileModal groupId={room.groupId} userId={profileUserId} onClose={() => setProfileUserId(-1)}/>
      </ModalContainer>
    </Wrapper>
  </>;
}
