import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {handHistoryModel} from "../../dataset";
import {requestGetHandHistory, requestRoomInfo} from "../../api";
import HistoryPlayer from "./HistoryPlayer";
import Flex from "../common/Flex";
import {useRecoilValue} from "recoil";
import {myInfoState} from "../../recoil/MyInfo";
import useRefreshRoomInfo from "../../api/from_server_game_refreshRoomInfo";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-left: 1px solid #43546C;
  color: #FFF;
  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;
  }

  > .title {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  > .gap {
    width: 48px;
  }
  .pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 20px;
    font-weight: 800;
    div {
      cursor: pointer;
      width: fit-content;
      height: fit-content;
     
    }
    .arrow {
      height: 24px;
    }
    .page-indicator {
      span {
        color: rgb(142,229,77);
      }
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 12px;
`;

const GameCards = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #17171B;
  display: flex;
  align-items: center;
  padding: 8px 12px;

  > .title {
    font-size: 10px;
    font-weight: 500;
    margin-right: 20px;
  }

  > .cards {
    flex: 1;
    display: flex;
    gap: 6px;

    > img {
      width: 24px !important;
      height: 32px;
    }
  }
`;

const DefaultView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${MEDIA_DESKTOP} {
    height: 346px;
  }
  .table {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > .seats {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > .dealer {
      position: absolute;
      width: 16px;
      left: -100%;
      top: -100%;
      z-index: 2;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.40));
    }

    > .pot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      img {
        width: 48px;
      }

      .sidepot {
        width: 69px;
        padding: 2px 8px;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: 600;
        border-radius: 15px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      }

      .box {
        width: 95px;
        padding: 4px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 4px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;

        > div:last-child {
          color: rgb(255, 199, 0);
          font-weight: 800;
        }
      }
    }
  }
`;

const NoHistory = styled(DefaultView)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MyHandView = styled.div`{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  > .card-wrapper {
    width: 80px;
    height: 80px;
    position: relative;
    > .card-row {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      z-index: 1;
      display: flex;
      flex-direction:row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      >img {
        width: 25px;
        object-fit: contain;
      }
    }

    > .background {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgb(38, 41, 45);
    }
  }

  > .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      font-size: 16px;
      font-weight: 800;

      > .hand-title {
        color: white;
      }

      > .profit {
        color: rgb(255, 208, 6);
      }
    }
    >.chip-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      >.chip-icon {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
      >.blind {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}`
const DetailView = styled.div`
  color: #FFF;
  padding: 12px 4px;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  > .round-item {
    font-size: 10px;
    @media ${MEDIA_DESKTOP} {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12px;
    }
    > .community {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      @media ${MEDIA_DESKTOP} {
        flex: 1
      }
      > div {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      > .round-name {
        width: 34px;
        font-weight: 500;
        @media ${MEDIA_DESKTOP} {
          width: 60px;
          font-size: 16px;
          font-weight: 800;
        }
      }

      > .round-cards {
        display: flex;
        gap: 4px;

        > img {
          width: 15px;
          height: 20px;
          @media ${MEDIA_DESKTOP} {
            width: 26px;
            height: fit-content;
          }
        }
      }

      > .count {
        font-weight: 700;
        color: rgba(255, 255, 255, 0.7);
        @media ${MEDIA_DESKTOP} {
          font-size: 14px;
        }
        > img {
          width: 12px;
          height: 12px;
          margin-right: 2px;
          margin-top: 2px;
          @media ${MEDIA_DESKTOP} {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }


        > &:last-child {
          opacity: 0.7;
        }
      }
    }

    > .bets {
      background: rgb(35, 37, 39);
      display: flex;
      flex-direction: column;
      padding: 8px 8px 8px 6px;
      border-radius: 8px;
      gap: 8px;
      @media ${MEDIA_DESKTOP} {
        flex: 1;
        font-weight: 800;
      }
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        font-size: 11px;
        @media ${MEDIA_DESKTOP} {
          font-size: 14px;
        }
        > .group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;

          &:first-child {
            width: 100px;
          }

          &:nth-child(2) {
            width: 70px;
            @media ${MEDIA_DESKTOP} {
              width: 130px;
            }
          }

          &:last-child {
            flex: 1;
          }
        }

        .position {
          font-style: italic;
          width: 24px;
          padding: 2px 3px 2px 2px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          font-size: 9px;
          font-weight: 700;
          text-align: center;
          border-radius: 12px;

          background: #17171B;
          overflow: hidden;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
        }

        .nickname {
          font-size: 11px;
        }

        .total {
          width: 100%;
          text-align: right;
        }

        .action {
          font-style: italic;
          width: 36px;
          padding: 2px 3px 2px 2px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          font-size: 9px;
          font-weight: 700;
          text-align: center;
          border-radius: 18px;
          background: #17171B;
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
          @media ${MEDIA_DESKTOP} {
            font-size: 12px;
            min-width: 52px;
          }
          &[data-action="FOLD"] {
            color: #FFF;
            background: rgba(255, 255, 255, 0.20);
          }

          &[data-action="SB"], &[data-action="BB"], &[data-action="STR"] {
            color: rgb(26, 31, 23);
            background: rgba(102, 168, 71, 0.8);
          }

          &[data-action="CHECK"], &[data-action="CALL"] {
            color: rgb(20, 22, 24);
            background: rgba(36, 81, 208, 0.8);
          }

          &[data-action="BET"], &[data-action="RAISE"] {
            color: rgb(20, 20, 20);
            background: rgba(236, 190, 79, 0.8);
          }

          &[data-action="ALLIN"] {
            color: #FFF;
            background: rgba(70, 155, 255, 0.8);
          }
        }
      }
    }
  }
`;

const Border = styled.div`
  width: 100%;
  min-height: 1px;
  background: #2D2D2D;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 11px;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .page-indicator {
      font-size: 14px;
      font-weight: 500;
      cursor: initial;

      > span {
        font-size: 14px;
        font-weight: 500;

        &:first-of-type {
          margin-right: 2px;
        }

        &:last-of-type {
          color: rgb(136, 218, 74);
          font-size: 14px;
          font-weight: 500;
          margin-left: 2px;
        }
      }
    }
  }
`;

const ChangeViewButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  font-size: 14px;
  color: rgb(136, 218, 74);
  font-weight: 600;
  width: 110px;
  aspect-ratio: 288/109;
  background-image: url(/new-image/Common/button_gameRecord.png);
  background-size: 100% 100%;
  cursor: pointer;
`;

function GameHistory(
  {
    roomId,
    handNumber,
    maxTableMember,
    onClose
  }: {
    roomId: number;
    handNumber: number;
    maxTableMember: number;
    onClose?: () => void;
  }
) {
  const orientation = useScreenOrientation();
  const seatsRef = useRef<HTMLDivElement>(null);
  const dealerButtonRef = useRef<HTMLImageElement>(null);

  const [currentPage, setCurrentPage] = useState<number>(handNumber - 1);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handHistory, setHandHistory] = useState<handHistoryModel[]>([]);
  const [roomData] = useRefreshRoomInfo();

  const endPage = useMemo(() => {
    return handNumber - 1;
  }, [handNumber]);

  const lastGameData = useMemo(() => {
    if (handHistory.length === 0) {
      return;
    }
    return handHistory.slice(-1)[0].lastGameData;
  }, [handHistory]);

  const dealerIndex = useMemo<number>(() => {
    const player = handHistory.find(x => x.position === 'dealer');
    return !player ? -1 : player.seat;
  }, [handHistory]);

  const players = useMemo(() => {
    const arr: {
      userId: number;
      seat: number;
      nickname: string;
      profileImg: string;
      isFold: boolean;
    }[] = [];

    for (let row of handHistory) {
      const player = arr.find(x => x.userId === row.userId);
      if (player) {
        player.isFold = row.fold;
      } else {
        arr.push({
          userId: row.userId,
          seat: row.seat,
          nickname: row.nickname,
          profileImg: row.profileImg,
          isFold: row.fold
        });
      }
    }

    return arr;
  }, [handHistory]);

  const betHistory = useMemo(() => {
    if (handHistory.length === 0 || !lastGameData) {
      return [];
    }

    const playersPosition: { [userId: number]: string } = {};
    const arr: { round: number, cards: number[], players: number[], pot: number, bets: any[] }[] = [];

    for (let row of handHistory) {
      const round = row.round;

      if (!arr[round]) {
        arr[round] = {
          round: round,
          cards: lastGameData.communityCards.slice(0, round + 2),
          players: [],
          pot: 0,
          bets: [],
        };
      }

      if (arr[round].players.indexOf(row.userId) === -1) {
        arr[round].players.push(row.userId);
      }

      if (!playersPosition[row.userId]) {
        let position = row.position;
        if (position === 'dealer') {
          position = 'BT';
        }
        playersPosition[row.userId] = position.toUpperCase();
      }

      arr[round].pot += row.bet;
      arr[round].bets.push({
        betId: row.betId,
        userId: row.userId,
        nickname: row.nickname,
        bet: row.bet,
        position: playersPosition[row.userId],
        action: ['', 'CHECK', 'CALL', 'FOLD', 'RAISE', 'ALLIN', 'BET', 'SB', 'BB', 'STR'][row.type]
      });
    }

    return arr;
  }, [handHistory, lastGameData]);

  const totalPot = useMemo(() => {
    if (!lastGameData) {
      return 0;
    }

    return lastGameData.pots.reduce((a, v) => a + v.amount, 0);
  }, [lastGameData]);

  const arrangeLayout = useCallback(() => {
    if (!seatsRef.current) {
      return;
    }

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const seatRects = [
      [0.5, 0.95],
      [0.08, 0.7],
      [0.08, 0.5],
      [0.08, 0.3],
      [0.32, 0.09],
      [0.68, 0.09],
      [0.92, 0.3],
      [0.92, 0.5],
      [0.92, 0.7]
    ];

    const buttonRects = [
      [0.5, 0.95],
      [0.08, 0.7],
      [0.08, 0.5],
      [0.08, 0.3],
      [0.32, 0.09],
      [0.68, 0.09],
      [0.92, 0.3],
      [0.92, 0.5],
      [0.92, 0.7]
    ];

    if (maxTableMember === 8) {
      seatRects[4][0] = 0.5;
      seatRects.splice(5, 1);
    }

    const width = seatsRef.current.offsetWidth;
    const height = seatsRef.current.offsetHeight;
    const left = seatsRef.current.offsetLeft;
    const top = seatsRef.current.offsetTop;

    const seats = seatsRef.current.querySelectorAll('.history-player');
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
    }

    if (dealerButtonRef.current && dealerIndex !== -1) {
      const dealerButton = dealerButtonRef.current;
      position([dealerButton], 0, left + width * buttonRects[dealerIndex][0], top + height * buttonRects[dealerIndex][1]);
    }
  }, [players, dealerIndex]);

  const toggleView = useCallback(() => {
    setShowDetail((b) => !b);
  }, []);

  const handlePrev = useCallback(() => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleLatest = useCallback(() => {
    setCurrentPage(endPage);
  }, [endPage]);

  const handleNext = useCallback(() => {
    if (currentPage + 1 <= endPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, endPage]);

  useEffect(() => {
    if (currentPage < 0) {
      return;
    }

    requestGetHandHistory(roomId, currentPage).then(({result}) => {
      const sorted = result.sort((a: any, b: any) => a.betId - b.betId);
      setHandHistory(sorted);
    });
  }, [roomId, currentPage]);

  useEffect(() => {
    if (currentPage < 1 && handNumber >= 2) {
      setCurrentPage(1);
    }
  }, [handNumber, currentPage]);

  useEffect(() => {
    arrangeLayout();
  }, [arrangeLayout]);

  const myInfo = useRecoilValue(myInfoState)
  const prize = useMemo(() => {
    if(myInfo === null || lastGameData === undefined || lastGameData === null) return 0
    const winnerIndex = lastGameData.winners.findIndex(_ => _.userId === myInfo.userId)
    if(winnerIndex > -1) {
      return "+"+lastGameData.winners[winnerIndex].amount.toLocaleString()
    }else{
      const betAmount = betHistory.map(_ => _.bets).flat().filter(_ => _.userId === myInfo.userId).map(_ => _.bet).reduce((a, v) => a + v, 0).toLocaleString()
      return "-"+betAmount
    }
  }, [myInfo,lastGameData, betHistory])
  return <Wrapper>
    {
      orientation === 'landscape' && <Header>
        {
          currentPage > 1 && (
            <div className='pagination'>
              <div className='arrow' onClick={handlePrev}>
                {
                  currentPage > 1 && (
                    <img src="/images/ic_result_prev.svg"/>
                  )
                }
              </div>
              <div className="page-indicator" onClick={handleLatest}>
                <span>{currentPage}</span>번째 게임
              </div>
              <div className='arrow' onClick={handleNext}>
                {
                  (handNumber - 1 > currentPage) && (
                    <img src="/images/ic_result_next.svg"/>
                  )
                }
              </div>
            </div>
          )
        }
      </Header>
    }
    {
      orientation === 'portrait' && <Header>
        <img className="back" src="/images/ic_header_back.svg" onClick={onClose}/>
        <div className="title">게임기록 상세보기</div>
        <div className="gap"/>
      </Header>
    }

    {
      handNumber > 1 ? <>
        <Content>
          {
            (showDetail || orientation === 'landscape') ? <>
            {
              orientation === 'portrait' &&  <MyHandView>
                <div className="card-wrapper">
                  <div className="card-row">
                    {
                      myInfo!== null && lastGameData?.cards.filter(_ => _.userId === myInfo.userId).map((cards, i) => {
                        return cards.cards.map((card: any) => {
                          return <img key={i} src = {`/images/card/style-1/${card}.png`} />
                        })
                      })
                    }
                  </div>
                  <div className="background"/>
                </div>
                <div className='info-wrapper'>
                  <div className='title-row'>
                    <div className='hand-title'>
                      핸드 {currentPage}
                    </div>
                    <div className='profit'>
                      {prize}
                    </div>
                  </div>
                  <div className='chip-row'>
                    <img className='chip-icon' src='/new-image/chip/ic_chip_01.png'/>
                    {
                      betHistory.length > 0 && <div className='blind'>{betHistory[0].bets[0].bet}/{betHistory[0].bets[1].bet}</div>
                    }

                  </div>
                </div>
              </MyHandView>
            }
              {
                (orientation === 'landscape' || !showDetail) && (
                  <DefaultView>
                    <div className="table">
                      <img onLoad={arrangeLayout}
                           style={{
                             width: 'calc(100% - 24px)',
                             objectFit: 'contain'
                           }}
                           src={`${orientation === 'landscape' ? "/new-image/PC/table_horizontal.png" : "/new-image/Mobile/table_vertical.png"}`}/>
                      <div ref={seatsRef} className="seats">
                        {
                          Array.from({length: maxTableMember}).map((_, i) => {
                            const player = players.find(x => x.seat == i);
                            if (!player) {
                              return <div key={i} className="history-player" data-seat={i}/>;
                            }

                            const winner = lastGameData?.winners.find(x => x.userId === player.userId);
                            const isWinner = winner !== undefined;
                            const prize = isWinner ? winner.amount : 0;
                            const cards = lastGameData?.cards.find(x => x.userId === player.userId);

                            return <HistoryPlayer
                              key={i}
                              userId={player.userId}
                              seat={player.seat}
                              nickname={player.nickname}
                              profileImg={player.profileImg}
                              folded={player.isFold}
                              winner={isWinner}
                              prize={prize}
                              cards={cards?.cards || []}
                            />;
                          })
                        }
                      </div>
                      <img ref={dealerButtonRef} className="dealer" src="/images/dealer_button.svg"/>
                      {
                        (lastGameData && lastGameData.pots) && (
                          <div className="pot">
                            <img src="/images/game_pot_chips.svg"/>
                            {
                              orientation === 'portrait' && <>
                                {
                                  lastGameData.pots.map((pot, i) => (
                                    <div key={i} className="sidepot">{pot.amount.toLocaleString()}</div>
                                  ))
                                }
                              </>
                            }
                            <div className="box">
                              <div>POT</div>
                              <div>{totalPot.toLocaleString()}</div>
                            </div>
                            {
                              orientation === 'landscape' && (
                                <GameCards>
                                  <div className="cards">
                                    {
                                      (lastGameData?.communityCards ?? []).map((card, i) => (
                                        <img key={i} src={`/images/card/style-1/${card}.png`} style={{
                                          objectFit: 'contain',
                                        }}/>
                                      ))
                                    }
                                  </div>
                                </GameCards>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </DefaultView>
                )
              }
              <DetailView>
                {
                  betHistory.map((x, i) => (
                    <div key={i} className="round-item">
                      <div className="community">
                        <div className="round-name">{['프리플랍', '플랍', '턴', '리버'][x.round]}</div>
                        <div className="round-cards">
                          {
                            x.round === 0 ? <>
                              <img src={`/images/card_back.png`}/>
                              <img src={`/images/card_back.png`}/>
                            </> : <>
                              {
                                x.cards.map((card, i) => (
                                  <img key={i} src={`/images/card/style-1/${card}.png`}/>
                                ))
                              }
                            </>
                          }
                        </div>
                        <Flex/>
                        <div className="count">
                          <img src="/new-image/icon/ic_people.png"/>
                          <div>{x.players.length.toLocaleString()}</div>
                        </div>
                        <div className="count">
                          <img src="/new-image/chip/ic_chip_01.png"/>
                          <div>{x.pot.toLocaleString()}</div>
                        </div>
                      </div>
                      <div className="bets">
                        {
                          x.bets.map((bet, betIdx) => (
                            <div key={bet.betId}>
                              <div className='group'>
                                <div className="position">{bet.position}</div>
                                <div className="nickname">{bet.nickname}</div>
                              </div>
                              <div className='group'>
                                <div className="action" data-action={bet.action}>{bet.action}</div>
                                <div className="amount">{bet.bet.toLocaleString()}</div>
                              </div>
                              <div className='group'>
                                <div className='total'>
                                  {x.bets.slice(0, betIdx + 1).map(_ => _.bet).reduce((a, v) => a + v, 0).toLocaleString()}
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </DetailView>
            </> : <>
              <GameCards>
                <div className="title">{currentPage}번째 게임</div>
                <div className="cards">
                  {
                    (lastGameData?.communityCards ?? []).map((card, i) => (
                      <img key={i} src={`/images/card/style-1/${card}.png`}/>
                    ))
                  }
                </div>
              </GameCards>
              <DefaultView>
                <div className="table">
                  <img onLoad={arrangeLayout} style={{
                    width: 'calc(100% - 24px)',
                    objectFit: 'contain'
                  }} src="/new-image/Mobile/table_vertical.png"/>
                  <div ref={seatsRef} className="seats">
                    {
                      Array.from({length: maxTableMember}).map((_, i) => {
                        const player = players.find(x => x.seat == i);
                        if (!player) {
                          return <div key={i} className="history-player" data-seat={i}/>;
                        }

                        const winner = lastGameData?.winners.find(x => x.userId === player.userId);
                        const isWinner = winner !== undefined;
                        const prize = isWinner ? winner.amount : 0;
                        const cards = lastGameData?.cards.find(x => x.userId === player.userId);

                        return <HistoryPlayer
                          key={i}
                          userId={player.userId}
                          seat={player.seat}
                          nickname={player.nickname}
                          profileImg={player.profileImg}
                          folded={player.isFold}
                          winner={isWinner}
                          prize={prize}
                          cards={cards?.cards || []}
                        />;
                      })
                    }
                  </div>
                  <img ref={dealerButtonRef} className="dealer" src="/images/dealer_button.svg"/>
                  {
                    (lastGameData && lastGameData.pots) && (
                      <div className="pot">
                        <img src="/images/game_pot_chips.svg"/>
                        {
                          lastGameData.pots.map((pot, i) => (
                            <div key={i} className="sidepot">{pot.amount.toLocaleString()}</div>
                          ))
                        }
                        <div className="box">
                          <div>POT</div>
                          <div>{totalPot.toLocaleString()}</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </DefaultView>
            </>
          }
        </Content>
        {
          orientation === 'portrait' &&    <Footer>
            <div>
              <ChangeViewButton onClick={toggleView}>
                {showDetail ? '기본보기' : '상세보기'}
              </ChangeViewButton>
            </div>
            <div className="pagination">
              <div onClick={handlePrev}>
                {
                  currentPage > 1 && (
                    <img src="/images/ic_result_prev.svg"/>
                  )
                }
              </div>
              <div className="page-indicator" onClick={handleLatest}>
                <span>{currentPage}</span>/<span>{endPage}</span>
              </div>
              <div onClick={handleNext}>
                {
                  (handNumber - 1 > currentPage) && (
                    <img src="/images/ic_result_next.svg"/>
                  )
                }
              </div>
            </div>
          </Footer>
        }

      </> : <>
        <Content>
          <NoHistory>진행된 게임이 없습니다.</NoHistory>
        </Content>
      </>
    }
  </Wrapper>;
}

export default GameHistory;
