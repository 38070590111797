import styled, {keyframes} from "styled-components";
import {useEffect, useState} from "react";

export const MODAL_TRANSITION_DURATION_MS = 100;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all ${MODAL_TRANSITION_DURATION_MS}ms linear;
  padding: 32px;
  
  &[data-show="true"] {
    animation: ${FadeIn} ${MODAL_TRANSITION_DURATION_MS}ms linear;
  }
  
  &[data-show="false"] {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
`;

const Dimmer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #101012;
  opacity: 0.5;
`;

const Content = styled.div`
  z-index: 1;
`;

function ModalContainer({show, children, onBackdropClick}: { show: boolean, children: any, onBackdropClick?: () => void }) {
  const [gone, setGone] = useState(false);

  useEffect(() => {
    if (show) {
      setGone(false);
    } else {
      setTimeout(() => {
        setGone(true);
      }, MODAL_TRANSITION_DURATION_MS);
    }

  }, [show]);

  if (gone) {
    return null;
  }

  return <Container data-show={show}>
    <Dimmer onClick={onBackdropClick}/>
    <Content>
      {children}
    </Content>
  </Container>
}

export default ModalContainer;