/* eslint-disable */
//auto gen
import {
    BET_TYPE,
    GAME_STATUS,
    MyPlayStatus,
    ROOM_JOIN_STATUS,
    ROOM_STATUS,
    ROOM_TYPE,
    RoomInfo,
    RoomStatus,
    winnerModel,
    RAKE_TYPE,
    GroupInfo,
    emitAfterBetModel,
    requestBetModel,
    handHistoryModel,
    GamePlayer,
    userProfileModel,
    tournamentRankingModel,
    winnerListModel,
    SimpleTournamentUser,
    CreateRingRoomInterface, CreateTournamentRoomInterface
} from '../dataset/common'
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: { result: number; roomId: number; }
}
type StoreFunc = (data: { result: number; roomId: number; }) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(roomId:number ,buyin:number ,seat:number ,opt?:SockClientOpt) : Promise<{ result: number; roomId: number; }>{
  return new Promise((resolve, reject) => {
    client.emit("game:buyin", roomId,buyin,seat, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([roomId,buyin,seat]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("game:buyin", (data:any)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (roomId:number ,buyin:number ,seat:number ,opt?:SockClientOpt) : [{ result: number; roomId: number; } | null, Function] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([roomId,buyin,seat]);
  const [Data, setData] = useState<{ result: number; roomId: number; } | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: { result: number; roomId: number; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[roomId]);

  useEffect(() => {
    const handleChange = (data: { result: number; roomId: number; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[roomId]);

  return [Data, () => request(roomId,buyin,seat,opt)]

}
