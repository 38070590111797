import {useSetRecoilState} from "recoil";
import {myInfoState} from "./recoil/MyInfo";
import {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {requestMyInfo, requestRegisterOrJoinTournament, useSocketIsConnect} from "./api";
import useDialog from "./hooks/useDialog";
import ModalContainer from "./components/common/ModalContainer";
import Dialog from "./components/Dialog";

import {setAckListener as setEndOfTournamentListener} from "./api/from_server_game_endOfTournament";
import {setAckListener as setMoveRoomClientListener} from "./api/from_server_game_moveRoomClient";
import {setAckListener as setMoveRoomWithTableBalancingListener} from "./api/from_server_game_moveRoomWithTableBalancing";
import useQueryParams from "./hooks/useQueryParams";
import {DialogProps} from "./recoil/Dialog";
import useScreenOrientation from "./hooks/useScreenOrientation";


function Container({children}: {
  children: any
}) {
  const [conn] = useSocketIsConnect();
  const history = useHistory();
  const [initialized, setInitialized] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = useQueryParams();
  const orientation = useScreenOrientation();

  const setMyInfo = useSetRecoilState(myInfoState);
  const {
    dialogs,
    openDialog,
  } = useDialog();

  useEffect(() => {
    if (typeof conn.isLogin === 'boolean') {
      if (conn.isLogin) {
        requestMyInfo().then(v => {
          setMyInfo(v.info);
          setInitialized(true);
        });
      } else {
        // history.replace('/login');
        setInitialized(true);
      }
    }
  }, [conn, initialized]);

  useEffect(function () {
    setEndOfTournamentListener((data: any) => {
      openDialog({
        title: '토너먼트 종료',
        text: `${data.name} 토너먼트에서 ${data.rank}위를 달성하여 ${data.prize.toLocaleString()} 의 머니를 획득하셨습니다.`,
        disableBackdropClick: true,
        onConfirm: () => {
          const platform = orientation === 'landscape' ? 'pc' : 'mobile'
          window.location.href = `${process.env.REACT_APP_HOST_URL}/${platform}/game/gameInfo?groupId=${data.groupId}`
        }
      });
    });

    setMoveRoomWithTableBalancingListener(async (data: any) => {
      const roomId = Number(queryParams.get('id') || 0);
      if (location.pathname == '/game' && (roomId == 0 || roomId == data.prevRoomId)) {
        openDialog({
          title: '테이블 밸런싱',
          text: `방을 이동하였습니다.`
        });
        let r = await requestRegisterOrJoinTournament(data.groupId);
        if (typeof (r.result) == "number") {
          history.push("/game?id=" + r.result);
        }
      }
    });

    setMoveRoomClientListener((data: {
      groupId: number,
      roomId: number
    }) => {
      const props: DialogProps = {
        title: '안내',
        text: '토너먼트가 시작되었습니다. 링 게임 중이라면 빠르게 정산하고 토너에 참가해주세요.',
        confirmText: '확인',
        disableBackdropClick: true,
      }

      const currentRoomId = Number(queryParams.get('id') || 0);
      if (!currentRoomId) {
        props.confirm = true;
        props.confirmText = '이동';
        props.cancelText = '닫기';
        props.onConfirm = async () => {
          let r = await requestRegisterOrJoinTournament(data.groupId);
          if (typeof (r.result) == "number") {
            history.push("/game?id=" + r.result);
          }
        }
      }

      openDialog(props);
    });

    return () => {
      setEndOfTournamentListener(null);
      setMoveRoomWithTableBalancingListener(null);
      setMoveRoomClientListener(null);
    }
  }, [queryParams]);

  if (!initialized) {
    return null;
  }

  return <>
    {children}
    {
      dialogs.map((dialog) => (
        <ModalContainer key={dialog.id} show={dialog.visibility} onBackdropClick={dialog.onBackdropClick}>
          <Dialog dialog={dialog}/>
        </ModalContainer>
      ))
    }
  </>;
};

export default Container;
