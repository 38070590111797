import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import {fixedNumber} from "../../utils/common";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  position: absolute;
  left: -100%;
  top: -100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${MEDIA_DESKTOP} {
    justify-content: flex-start;
    padding-bottom: 70px;
  }
`;

const ChipsImage = styled.img`
  &[data-amount="0"] {
    opacity: 0;
  }
  width: 12px;
  height: 12px;
  @media ${MEDIA_DESKTOP} {
    width: 24px;
    height: 24px;
  }
`;

const PotItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  gap: 2px;
  
  @media ${MEDIA_DESKTOP} {
    flex-direction: row;
    height: 40px;
    gap: 8px;
  }
  
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: rgba(24, 26, 29, 0.50);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
    margin: 2px 8px;
    border-radius: 15px;
    padding: 6px 12px;
    .label {
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      
      transform: scale(0.9);

      @media ${MEDIA_DESKTOP} {
        font-size: 18px;
        font-weight: 800;
        transform: none;
      }
    }
  }
`;

const TotalPot = styled.div`
  min-width: 120px;
  border-radius: 12px;
  background: rgba(24, 26, 29, 0.30);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3) inset;
  padding: 4px 10px;
  color: rgb(255, 199, 0);
  font-size: 14px;
  font-weight: 700;

  > div {
    color: #FFF;
  }
  
  @media ${MEDIA_DESKTOP} {
    min-width: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 12px;
    font-size: 20px;
    
    > div {
      font-size: 20px;
    }
  }
`;

function FieldPots(
  {
    pots,
    BB,
    children
  }: {
    pots: number[];
    BB: number;
    children?: any
  }
): JSX.Element {
  const orientation = useScreenOrientation();

  const totalPot = useMemo(() => {
    return pots.reduce((a, v) => a + v, 0);
  }, [pots]);
  const gameOption = useRecoilValue(gameOptionState);

  return <Wrapper className="field-pots">
    <PotItems>
      {
        pots.filter(pot => pot > 0).map((amount, i) => (
          <div key={i}>
            <ChipsImage className="field-pot" src="/new-image/chip/ic_chip_01.png" data-amount={totalPot}/>
            <div className="label">
              <ReactFontSizeByTextLength changePerChar={1}>
                <span>{gameOption.alwaysBB ? `${fixedNumber(amount / BB, 2)} BB` : amount.toLocaleString()}</span>
              </ReactFontSizeByTextLength>
            </div>
          </div>
        ))
      }
    </PotItems>
    <TotalPot>
      <div>POT</div>
      <ReactFontSizeByTextLength changePerChar={1}>
        <span>{gameOption.alwaysBB ? `${totalPot / BB} BB` : totalPot.toLocaleString()}</span>
      </ReactFontSizeByTextLength>
    </TotalPot>
    {children}
  </Wrapper>;
}

export default FieldPots;
