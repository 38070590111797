import styled from "styled-components";
import {useCallback, useMemo} from "react";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{ mode?: 'check' | 'fold' | 'call' | 'raise'; flex?:boolean; joinGame?: boolean; dark?: boolean, raise?: boolean, disabled?:boolean }>`
  position: relative;
  display: flex;
  justify-content: center;

  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(16, 16, 18, 0.20);

  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  cursor: pointer;
  transition: filter 0.1s linear;
  align-items: center;
  white-space: nowrap;
  ${p => p.flex ? `
  flex: 1;
  padding: 15px 0px;
  `: `
  padding: 15px 19px;
  width: 170px;
  aspect-ratio: 2.8724832215;
  `}
  ${p => p.mode ? `
    background: none;
    ${p.mode === 'raise' ? `
      color: rgb(229,181,12);
    `: ``}
    ${p.mode === 'call' ? `
      color: rgb(98,230,15);
    `: ``}
    ${(['check','fold'].includes(p.mode))  ? `
      color: white;
    `: ``}
  ` : `
    color: #FFF;
    &::before {
      width: 100%;
      height: 0.5px;
      //transform: scale(1, 0.5);
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, transparent, white, transparent);
    }
    ${p.joinGame ? `
    background: url("/new-image/Mobile/buttonFrame_blindStandbyOn.png") no-repeat center;
    `: `
    background: url("/new-image/Mobile/buttonFrame_blindStandbyOff.png") no-repeat center;
    `}
  `};
  background-size: 100% 100%;

  filter: ${p=>p.disabled == true ? "grayscale(1)":""};
  opacity: ${p=>p.disabled == true ? "0.5":"1"};

  ${p => p.dark && `
    background: radial-gradient(102.77% 100% at 50% 100%, rgba(168, 168, 168, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(0, 0, 0, 0.50);
  `};



  &:hover {
    filter: brightness(1.5);
  }

  &:active {
    filter: brightness(0.7);
  }
  >.background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

const Checkbox = styled.div<{ checked?: boolean }>`
  width: 12px;
  height: 12px;
  background-image: url(/new-image/Common/checkBox_off.png);
  background-size: 100% 100%;
  margin-right: 4px;

  ${p => p.checked && `
    background-image: url(/new-image/Common/checkBox_on.png);
  `};

  @media ${MEDIA_DESKTOP} {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

const Raise = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  color: rgb(124,124,124);
  font-size: 11px;
  font-weight: 600;
`;


function InGameButton({mode, flex, joinGame, children, checked, subTitle, disabled, raise, bet, dark, onChecked, onClick}: {
  children: any
  checked?: boolean
  mode?: 'check' | 'fold' | 'call' | 'raise'
  raise?: boolean
  subTitle?: string
  bet?: boolean
  disabled?: boolean
  dark?: boolean
  onChecked?: (e: any) => void
  onClick?: (e: any) => void
  joinGame?: boolean;
  flex?: boolean;
}) {
  const isCheckbox = useMemo(() => {
    return onChecked
  }, [onChecked]);

  const handleClick = useCallback((e: any) => {
    if(disabled) {
      return;
    } else if (onChecked) {
      onChecked(e);
    } else if (onClick) {
      onClick(e)
    }
  }, [isCheckbox, onClick, onChecked, disabled]);

  return <Wrapper mode={mode} flex={flex} joinGame={joinGame} dark={dark || (isCheckbox && !checked)} raise={raise || bet} onClick={handleClick} disabled={disabled}>
    {
      (mode === 'check' || mode==='fold') && <img className='background' src='/new-image/Mobile/buttonFrame_gray.png'/>
    }
    {
      (mode === 'call') && <img className='background' src='/new-image/Mobile/buttonFrame_green.png'/>
    }
    {
      (mode === 'raise') && <img className='background' src='/new-image/Mobile/buttonFrame_yellow.png'/>
    }
    {
      isCheckbox && (
        <Checkbox checked={checked}/>
      )
    }
    <Raise>{subTitle}</Raise>
    {/* { bet && (<Raise>bb</Raise>) }
    { raise && (<Raise>pot</Raise>) } */}
    {children}
  </Wrapper>
}

export default InGameButton
