import "./overrides";
import React, {useLayoutEffect} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {RecoilRoot} from "recoil";
import theme from './theme';
import Container from './Container';
import RejoinCheck from "./components/RejoinCheck";
import Game from "./pages/Game";
import DirectLink from "./pages/DirectLink"

const App: React.FC = () => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <RecoilRoot>
        <Container>
          <RejoinCheck/>
          <Switch>
            <Route path={'/game'} exact>
              <Game/>
            </Route>
            <Route path={'/link'} exact>
              <DirectLink />
            </Route>
          </Switch>
        </Container>
      </RecoilRoot>
    </BrowserRouter>
  </ThemeProvider>);
};
export default App;
