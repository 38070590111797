import styled from "styled-components";
import {useMemo} from "react";

const CircleProgressWrapper = styled.div<{CIRCUMFERENCE: number; percent: number}>`{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 22px);
  height: calc(100% + 22px);
  z-index: -1;
  .circle_progress_wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .circle_progress {
    width: 100%;
    height: 100%;
    transform: rotate(90deg); 
  }
  .frame, .bar { fill: none; }
  .frame { stroke: #03c75a;; }
  .bar {
    transition: all 1s linear;
    stroke: black;
    stroke-linecap: round;
    stroke-dashoffset: ${p => p.percent};
    stroke-dasharray: ${p=> p.CIRCUMFERENCE};
  }
}`

interface CircleProgressProps {
  className: string;
  value: number;
  max: number;
  text?: string;
}
const TimerProgress = ({className,value,max, text}:CircleProgressProps) => {
  const RADIUS = 46;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
  const dashoffset = useMemo(() => {
    const progress = value / max;
    return CIRCUMFERENCE * progress;
  }, [value, max, CIRCUMFERENCE]);
  return <CircleProgressWrapper className={className} CIRCUMFERENCE={CIRCUMFERENCE} percent={dashoffset}>
    <svg className="circle_progress" width="120" height="120" viewBox="0 0 120 120">
      <circle className="frame" cx="50%" cy="50%" r={RADIUS} strokeWidth={6}/>
      <circle className="bar" cx="50%" cy="50%" r={RADIUS} strokeWidth={6}/>
    </svg>
  </CircleProgressWrapper>
}

export default TimerProgress;
