import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled, {keyframes} from "styled-components";
// @ts-ignore
import {Hand} from "pokersolver";
import ProgressBar from "../common/ProgressBar";
import {BET_TYPE, CARD_INFO, GamePlayer, ROOM_JOIN_STATUS, winnerModel} from "../../dataset";
import ActionTag from "./ActionTag";
import PokerCard from "./PokerCard";
import Const from "../../Const";
import moment from "moment/moment";
import Lottie from 'lottie-react';
import winLottie from '../../lotties/win.json';
import {FadeIn} from "../../keyframes/FadeIn";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {parseDatetime} from "../../constants/moment";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import {fixedNumber} from "../../utils/common";
import TimerProgress from "../common/TimerProgress";
import WinningRate from "./WinningRate";

const Wrapper = styled.div<{
  me?: boolean
}>`
  width: 98px;
  height: 80px;
  position: absolute;
  left: -100%;
  top: -100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;
  &[data-me="true"] {
    width: 102px;
    height: 110px;
  }

  @media ${MEDIA_DESKTOP} {
    width: 150px;
    height: 164px;
    &[data-me="true"] {
      margin-top: 40px;
      width: 230px;
      height: 250px;
    }
  }

  &[data-fold="true"] {
    .player-wrapper {
      opacity: 0.6;
      transition: opacity 0.2s linear 1s, filter 0.2s linear 1s;

      // TODO: 윈도우 크롬 버그때문에 강제로 리페인팅 하도록 애니메이션 적용함.
      // 버그 해결되면 이부분 지워야 함.. 퍼포먼스 문제 있을 수 있음.
      &::before {
        position: absolute;
        width: 1px;
        height: 1px;
        content: ' ';
        animation: BugSolver 1s infinite;
        background-color: black;
        will-change: transform;

        @keyframes BugSolver {
          to {
            transform: rotate(0.1deg);
          }
        }
      }

      // .profileImg {
      //   border: 2px solid transparent !important;
      // }
    }
  }

  > .player-wrapper {
    width: 100%;

    &[data-me="true"] {
      width: 160px;
      height: 150px;

      > div.top {
        > .profileImg {
          width: 42px;
          aspect-ratio: 1 / 1;
        }
      }

      > div.bottom {
        width: 100%;
      }
    }

    > div.top {
      width: 100%;
      position: relative;

      > .action {
        left: 50%;
        top: -2px;
        transform: translate(-50%, -100%);
        z-index: 10;
        ${p => p.me ? `
          top: 6%;
          right: -15%;
          left: unset;
        ` :`
          top: 6%;
          right: -25%;
          left: unset;
        `}
        @media ${MEDIA_DESKTOP} {
          ${p => p.me ? `
          top: 10%;
          right: -10%;
          left: unset;
          ` :`
          top: 10%;
          right: -34%;
          left: unset;
          `}
        }
      }

      > .profileImg {
        border: 2px solid #FFF;
        width: ${p => p.me ? 102 : 68}px;
        aspect-ratio: 1 / 1;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        transition: all 0.2s linear;
        margin: 0 auto;


        @media ${MEDIA_DESKTOP} {
          width: 100%;
          height: fit-content;
          aspect-ratio: 1 / 1;
        }

        &[data-winner=true] {
          transition: border 0.2s linear;
          border: 4px solid #FFEDAC;
          filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
        }
      }
    }

    > div.bottom {
      width: 100%;
      position: relative;

      > .vpip {
        color: black;
        position: absolute;
        width: 19px;
        height: 19px;
        font-size: 11px;
        font-weight: 800;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.50);
        z-index: 2;
        
        ${p => p.me ? `
            left: 14px;
            transform: translateY(-32px);
            border-width: 1.5px;
            border-color: rgb(255, 255, 255);
            border-style: solid;
            border-radius: 50%;
            background-image: -moz-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
            background-image: -webkit-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
            background-image: -ms-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
        `: `
           &[data-seat="5"] {
              left: -20px;
            }
            &[data-seat="6"] {
              left: -20px;
            }
            &[data-seat="7"] {
              left: -20px;
            }
            
            left: 18px;
            transform: translateY(-32px);
            border-width: 1.5px;
            border-color: rgb(255, 255, 255);
            border-style: solid;
            border-radius: 50%;
            background-image: -moz-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
            background-image: -webkit-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
            background-image: -ms-linear-gradient( 90deg, rgb(218,218,218) 0%, rgb(231,231,231) 53%);
        `}

        @media ${MEDIA_DESKTOP} {
          width: 32px;
          height: 32px;
          font-size: 18px;
        }
      }

      > .profileName {
        border-radius: 10px;
        background-image: -moz-linear-gradient( 90deg, rgb(18,19,19) 0%, rgb(13,14,14) 100%);
        background-image: -webkit-linear-gradient( 90deg, rgb(18,19,19) 0%, rgb(13,14,14) 100%);
        background-image: -ms-linear-gradient( 90deg, rgb(18,19,19) 0%, rgb(13,14,14) 100%);
        padding: 5px 10px;
        transform: translateY(-15px);
        transition: all 0.1s linear;
        position: relative;
        z-index: 1;

        @media ${MEDIA_DESKTOP} {
          padding: 8px 10px;
        }

        > .nickname {
          font-size: 16px;
          font-weight: 500;
          line-height: 1;

          @media ${MEDIA_DESKTOP} {
            font-size: 26px;
            font-weight: 700;
          }
        }

        > .stack {
          line-height: 1;
          color: rgb(255, 199, 0);
          text-align: center;
          font-size: 16px;
          font-weight: 600;

          @media ${MEDIA_DESKTOP} {
            font-size: 26px;
            font-weight: 700;
          }
        }

        .time-guage {
          height: 4px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 0;
          border-radius: 0 0 5px 5px;
          overflow: hidden;

          &::after {
            transition: all 1s linear;
          }
        }
      }
    }
  }

  > div.seat-button {
    width: 64px;
    cursor: pointer;
    
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  > .seat-button-wrapper {
    display: none;
    position: relative;
    width: 88px;
    height: 88px;
    @media ${MEDIA_DESKTOP} {
      width: 160px;
      height: 160px;
    }

    &[data-hide="true"] {
      display: none;
    }

    > .seat {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      font-size: 20px;
      font-weight: 700;
      @media ${MEDIA_DESKTOP} {
        font-size: 30px;
      }
    }

    > .background {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }
`;

const CardDeck = styled.div<{
  zoom: boolean
  me: boolean
}>`
  position: absolute;
  ${p => p.me ? `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    left: calc(100% + 18px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  ` : `
    bottom: 12px;
    right: 0px;
    &[data-inner-seat="5"] {
      width: 100%;
      right: 50px;
    }
    &[data-inner-seat="6"]{
      width: 100%;
      right: 50px;
    }
    &[data-inner-seat="7"]{
      width: 100%;
      right: 50px;
    }
    
  `}
  
  ${p => p.zoom && `
    transition: transform 0.1s linear;
    transform: scale(1.7);
    transform-origin: bottom right;
    ${p.me ? ``: `
      &[data-winner="true"] {
        right: unset !important;
        left: 50% !important;
        transform: scale(1.7) translateX(-35%);
      } 
    `}
  `};


  @media ${MEDIA_DESKTOP} {
    ${p => p.zoom? `
      transform: scale(1.5);
       ${p.me ? ``: `
      &[data-winner="true"] {
        right: unset !important;
        left: 50% !important;
        transform: scale(1.5) translateX(-32%);
      } 
    `}
    `: ``};
  }

  .player-card {
    width: 12px;
    height: 18px;
    transition: all 0.5s;
    opacity: 0;


    ${p => p.me ? `
      width: 60px;
      height: auto;
      
      &:first-child {
        transform: translate(0px, 0) ;
      }
  
      &:last-child {
        transform:  translate(4px, 0) ;
      }
      
    `: `
      &:first-child {
        transform: scale(1.8) translate(3px, 0) rotate(-9deg);
      }
  
      &:last-child {
        transform: scale(1.8) translate(2px, 0) rotate(9deg);
      }
    `};

    @media ${MEDIA_DESKTOP} {
      width: 36px;
      height: auto;
       ${p => p.me ? `
         width: 130px;
         height: auto;
         &:first-child {
          transform: translate(0px, 0) ;
         }
  
         &:last-child {
          transform:  translate(8px, 0) ;
         }
       ` :`
        &:first-child {
          transform: scale(1.8) translate(5px, 0) rotate(-5deg);
        }
        
        &:last-child {
          transform: scale(1.8) translate(2px, 0) rotate(5deg);
        }
        &[data-winner="true"] {
          &:first-child {
           transform: scale(1.8) translate(5px, 0) rotate(-5deg);
          }
          
          &:last-child {
           transform: scale(1.8) translate(2px, 0) rotate(5deg);
          }
        }
       `}
    }
  }
`;

const HandRanking = styled.div`
  position: absolute;
  bottom: -12px;
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%) scale(0.9);
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  background-image: -moz-linear-gradient( 90deg, rgba(231,231,231, 0.3) 0%, rgba(100,100,100, 0.3) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgba(231,231,231, 0.3) 0%, rgba(100,100,100, 0.3) 100%);
  background-image: -ms-linear-gradient( 90deg, rgba(231,231,231, 0.3) 0%, rgba(100,100,100, 0.3) 100%);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.18px;
  white-space: nowrap;
  border-radius: 14px;
  padding: 2px 4px;

  @media ${MEDIA_DESKTOP} {
    font-size: 22px;
    letter-spacing: -0.22px;
    transform: translateX(-50%);
    padding: 6px;
    border-radius: 22px;
    bottom: -30px;
  }
`;

const SeatButton = styled.img<{
  disabled: boolean
}>`
  width: 64px;
  cursor: pointer;

  @media ${MEDIA_DESKTOP} {
    width: 148px;
  }

  ${p => p.disabled && `
    display: none;
  `};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

const Timer = styled.div<{
  afk: boolean
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 6px 12px rgba(0, 0, 0, 0.40);
  font-size: 16px;
  font-weight: 600;

  ${p => p.afk && `
    transform: scale(0.8);
  `};
  > span {
    display: block;
    font-size: 18px;
    font-weight: 800;
    color: white;
    @media ${MEDIA_DESKTOP} {
      font-size: 32px;
    }
  }
  @media ${MEDIA_DESKTOP} {
    font-size: 24px;
    padding-top: 10px;
  }
`;


const PrizeText = styled.div`
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  background: linear-gradient(180deg, #FFF 0%, #FFC92C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0px 2px 4px black);

  @media ${MEDIA_DESKTOP} {
    font-size: 24px;
  }
`;

const WinnerTextScale = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

const WinnerText = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  animation: ${WinnerTextScale} 1s;
  z-index: 3;
  @media ${MEDIA_DESKTOP} {
    bottom: -6px;
  }
`;

const WinnerRotate = keyframes`
  from {
    transform: scale(2) rotate(0);
  }
  to {
    transform: scale(2) rotate(360deg);
  }
`;

const WinnerLight = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url(/new-image/Common/winningEffect02.png) no-repeat center;
  background-size: contain;
  animation: ${WinnerRotate} 10s linear infinite, ${FadeIn} 0.5s linear;
`;
const WinnerBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background: url(/new-image/Common/winningEffect01.png) no-repeat center;
  background-size: contain;
  animation: ${WinnerRotate} 10s linear infinite, ${FadeIn} 0.5s linear;
`;

const StyledWinningRate = styled(WinningRate)`
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;

  @media ${MEDIA_DESKTOP} {
    bottom: 14px;
  }
`;

function Player(
  {
    idx,
    player,
    act,
    cards,
    betNow,
    winners,
    rate,
    disabled,
    leftSec,
    communityCards,
    me,
    BB,
    onClickSeat,
    onClickProfile,
  }: {
    idx: number;
    player?: GamePlayer;
    act?: number;
    cards: number[];
    betNow?: boolean;
    winners?: winnerModel[];
    rate?: number;
    disabled?: boolean;
    leftSec?: number;
    communityCards: number[];
    me?: boolean;
    BB: number;
    onClickSeat?: (s: number) => void;
    onClickProfile?: (userId: number) => void;
  }
): JSX.Element {

  const timerRef = useRef<HTMLDivElement>(null);
  const gameOption = useRecoilValue(gameOptionState);
  const [isAfk, setAfk] = useState<boolean>(false);

  const isSeated = useMemo<boolean>(() => {
    return !!player;
  }, [player]);

  const [winner, prize] = useMemo(() => {
    const ret = [false, 0];
    if (!player || !winners || !Array.isArray(winners)) {
      return ret;
    }

    const idx = winners.findIndex(x => x.userId === player?.userId);
    if (idx !== -1) {
      ret[0] = true;
      ret[1] = winners[idx].amount;
    }

    return ret;
  }, [player?.userId, winners]);

  const {
    isPlaying,
    isFold,
    isObserve
  } = useMemo(() => {
    const status = player?.status;
    return {
      isFold: act === BET_TYPE.FOLD || status === ROOM_JOIN_STATUS.FOLD,
      isPlaying: status === ROOM_JOIN_STATUS.PLAYING || status === ROOM_JOIN_STATUS.FOLD,
      isObserve: status === ROOM_JOIN_STATUS.OBSERVE || status === ROOM_JOIN_STATUS.BUYIN_READY,
    };
  }, [player?.status, act]);

  const showActionTag = useMemo(() => {
    return !betNow && !winner;
  }, [betNow, winner]);

  const showTimer = useMemo(() => {
    return !!player?.leaveRoomTime;
  }, [player?.leaveRoomTime]);

  const showCards = useMemo(() => {
    return Array.isArray(cards) && cards[0] !== undefined && cards[1] !== undefined;
  }, [cards]);

  const handRankingText = useMemo<string>(() => {
    if (cards.length === 2) {
      return Hand.solve([
        ...cards.map(x => CARD_INFO[x]),
        ...communityCards.map(x => CARD_INFO[x])
      ]).descr;
    } else {
      return '';
    }
  }, [cards]);

  const handleClickProfile = useCallback(() => {
    if (player && !me) {
      onClickProfile && onClickProfile(player.userId);
    }
  }, [me, player, onClickProfile]);

  const handleClickSeat = useCallback(() => {
    onClickSeat && onClickSeat(idx);
  }, [idx, onClickSeat]);

  useEffect(() => {
    if (!player?.leaveRoomTime || !timerRef.current) {
      return;
    }

    if (player.leaveRoomTime === 'forceFold') {
      setAfk(true);
      return;
    } else {
      setAfk(false);
    }

    const interval = setInterval(() => {
      if (!timerRef.current) {
        return;
      }

      const leaveRoomTime = parseDatetime(player?.leaveRoomTime);
      const leftSec = leaveRoomTime.unix() - moment().unix();
      if (leftSec >= 0 && timerRef.current.innerText !== leftSec.toString()) {
        timerRef.current.innerText = leftSec.toString();
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [player?.leaveRoomTime]);

  return <Wrapper
    className={`game-seat seat-${player?.userId} ${idx === 0 ? 'big' : ''}`}
    me={me}
    data-play={isFold || isObserve ? "0" : "1"}
    data-fold={isFold || isObserve || isAfk}
    data-me={me}
  >
    {
      (isSeated && player) ? (
        <div className={"player-wrapper"} onClick={handleClickProfile}>
          <div className="top">
            {
              leftSec !== undefined && (
                <TimerProgress className={''} value={leftSec - 1} max={Const.ACTION_LIMIT_TIME - 1}/>
              )
            }
            {
              (showActionTag && act) && (
                <ActionTag act={act}/>
              )
            }
            <div className="profileImg"
                 data-betnow={betNow}
                 data-winner={winner}
                 data-me={me}
                 style={{backgroundImage: `url(/new-image/profile/profile_0${Number(player.profileImg) + 1}.png)`}}
            />
            {
              (isPlaying || isFold) && (
                <CardDeck data-winner={Boolean(winner && prize)} className="card-deck" data-inner-seat={idx} data-seat={player.seat} me={me!} zoom={!me && showCards}>
                  <PokerCard data-winner={Boolean(winner && prize)}  className="player-card" card={cards[0]} flip={showCards} delay={100}/>
                  <PokerCard data-winner={Boolean(winner && prize)}  className="player-card" card={cards[1]} flip={showCards} delay={100}/>
                </CardDeck>
              )
            }
            {
              (winner && prize) && <>
                    <WinnerBackground/>
                    <WinnerLight/>
                    <WinnerText src='/new-image/Common/winText.png'/>
                    <PrizeText>+{prize.toLocaleString()}</PrizeText>
                </>
            }
            <StyledWinningRate rate={rate}/>
          </div>
          <div className="bottom">
            <div className="vpip">{Math.floor(player.vpip * 100)}</div>
            <div className="profileName">
              <div className="nickname">{player.nickname}</div>
              <div className="stack">
                <ReactFontSizeByTextLength changePerChar={2}>
                 <span>{
                   gameOption.alwaysBB ? `${fixedNumber(player.stackSize / BB, 2)} BB` : player.stackSize.toLocaleString()
                 }</span>
                </ReactFontSizeByTextLength>

              </div>
            </div>
            {
              (!!handRankingText) && (
                <HandRanking>{handRankingText}</HandRanking>
              )
            }
          </div>
        </div>
      ) : (
        <div className='seat-button-wrapper' onClick={handleClickSeat} data-hide={disabled!}>
          <span className='seat'>착석</span>
          <SeatButton className='background' src="/new-image/Common/buttonFrame_seat.png" disabled={false}/>
        </div>
      )
    }
    {
      showTimer && (
        <Timer ref={timerRef} className="timer" afk={isAfk}>
          {
            isAfk ? <span>자리비움</span> : ''
          }
        </Timer>
      )
    }
  </Wrapper>;
}

export default Player;
