import {atom, selector} from "recoil";
import {MyInfo} from "../dataset";

export const myInfoState = atom<MyInfo | null>({
  key: 'myInfoState',
  default: null
});

export const userIdSelector = selector<number>({
  key: 'userIdSelector',
  get: ({get}) => {
    return get(myInfoState)?.userId || 0;
  }
});