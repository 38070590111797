import React from "react";
import styled from "styled-components";
import {ReactFontSizeByTextLength} from "../../utils/ReactFontSizeByTextLength";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  left: -100%;
  top: -100%;
  user-select: none;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  @media ${MEDIA_DESKTOP} {
    gap: 6px;
  }
  img {
    width: 22px;
    z-index: 2;

    &[data-amount="0"] {
      opacity: 0;
    }

    @media ${MEDIA_DESKTOP} {
      width: 36px;
      height: 36px;
    }
  }

  .amount {
    width: fit-content;
    display: inline-block;
    color: white;
    font-weight: 800;
    font-size: 13px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.50);

    @media ${MEDIA_DESKTOP} {
      font-size: 20px;
    }
  }
`;

function PlayerPot(
  {
    amount,
    userId
  }: {
    amount: number,
    userId: number
  }
): JSX.Element {
  return <Wrapper className={'game-player-pot'}>
    <img className={`pot-${userId}`} src="/new-image/chip/chip_01.png" data-amount={amount}/>
    {
      amount > 0 && (
        <ReactFontSizeByTextLength changePerChar={2}>
          <div className='amount'>{amount.toLocaleString()}</div>
        </ReactFontSizeByTextLength>
      )
    }
  </Wrapper>;
}

export default PlayerPot;
