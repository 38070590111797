import styled from "styled-components";

const Modal = styled.div<{hasBorder?:boolean}>`
  width: 100%;
  min-width: 311px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
  ${p => p.hasBorder ? `
    border: 2px solid rgb(55,57,61);
  ` : ``}
`;

export default Modal;
