import styled from "styled-components";
import {useCallback, useEffect, useRef} from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  align-items: flex-end;
  gap: 4px;
  >.item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-items: flex-end;
    gap: 4px;
  }
`;

const Gauge = styled.div`
  width: 114px;
  height: 248px;
  background-image: url(/new-image/Mobile/frame_bet.png);
  background-size: contain;
  position: relative;
  >.button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    bottom: 9px;
    left: 8px;
  }
  >.label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 800;
    width: 114px;
    height: 40px;
    color: rgb(255, 199, 0);
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const BetButton = styled.div`{
  font-size: 13px;
  font-weight: 600;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  position: relative;
  cursor: pointer;
  >span {
    color: white;
    font-size: 13px;
    font-weight: 800;
    z-index: 2;
  }
  >.background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }
}`
const Fill = styled.div`
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: 6px;
  height: 0;
  will-change: height;
  background-image: url(/new-image/Mobile/guage_bet.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
  pointer-events: none;
  user-select: none;
`;

const Thumb = styled.div`
  width: 100%;
  position: absolute;
  bottom: 30px;
  will-change: bottom;
  left: 23px;
  text-align: center;
  transform: translateY(50%);

  > .chip {
    position: relative;
    width: 40px;
    height: 40px;
    background-image: url(/new-image/Mobile/slider_bet.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;

    > .label {
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translate(-50%, 100%);
      padding: 2px 8px;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) inset;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const Button = styled.div<{
  cancel?: boolean
}>`
  width: 114px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  > .background {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  >span {
    font-size: 16px;
    font-weight: 600;
    z-index: 2;
    ${p => p.cancel ? `
    color: #FFF;
  `: `
    color: rgb(98,230,15);
  `}
  }
  
`;

function BetGaugeVertical(
  {
    min,
    max,
    pot,
    bb,
    onConfirm,
    onCancel
  }: {
    min: number;
    max: number;
    pot: number;
    bb: number;
    onConfirm: (amount: number) => void;
    onCancel: () => void;
  }
) {
  const ref = useRef<HTMLImageElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<any>(null);

  useEffect(() => {
    if (!ref.current || !thumbRef.current) {
      return;
    }

    const gaugeHeight = ref.current.offsetHeight - 90;

    const handleMouseEvent = (e: any) => {
      if (!thumbRef.current || !fillRef.current || !labelRef.current) {
        return;
      }

      e.stopPropagation();

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const thumbBottom = Number((thumbRef.current.style.bottom || '0').replace('px', ''));
        if (!prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          prevState.current = {
            thumbBottom: thumbBottom,
            originY: clientY
          };
        }
      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          const dy = prevState.current.originY - clientY;
          const newBottom = Math.max(Math.min(prevState.current.thumbBottom + dy, gaugeHeight), 0) + 30;
          thumbRef.current.style.bottom = newBottom + 'px';
          fillRef.current.style.height = newBottom + 'px';

          const portion = Math.max(Math.min(newBottom / gaugeHeight, 1), 0);
          const amount = Math.floor(min + (max - min) * portion);
          labelRef.current.setAttribute('data-amount', amount.toString());
          labelRef.current.innerText = amount.toLocaleString();
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (prevState.current) {
          prevState.current = null;
        }
      }
    };

    thumbRef.current.addEventListener('mousedown', handleMouseEvent);
    thumbRef.current.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      if (thumbRef.current) {
        thumbRef.current.removeEventListener('mousedown', handleMouseEvent);
        thumbRef.current.removeEventListener('touchstart', handleMouseEvent);
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('touchmove', handleMouseEvent);
        window.removeEventListener('mouseup', handleMouseEvent);
        window.removeEventListener('touchend', handleMouseEvent);
      }
    };
  }, [min, max]);

  const setAmount = useCallback((amount: number) => {
    if (!labelRef.current) {
      return;
    }
    labelRef.current.setAttribute('data-amount', amount.toString());
    labelRef.current.innerText = amount.toLocaleString();

    if (!thumbRef.current || !fillRef.current || !ref.current) {
      return;
    }

    const gaugeHeight = ref.current?.offsetHeight - 90;
    const portion = Math.max(Math.min((amount - min) / (max - min), 1), 0);
    const newBottom = Math.max(Math.min(gaugeHeight * portion, gaugeHeight), 0) + 30;
    thumbRef.current.style.bottom = newBottom + 'px';
    fillRef.current.style.height = newBottom + 'px';

  }, [max, min])

  const handleConfirm = useCallback(() => {
    if (!labelRef.current) {
      return;
    }

    const amount = labelRef.current.getAttribute('data-amount');
    if (amount !== null) {
      onConfirm && onConfirm(Number(amount));
    }
  }, [onConfirm]);

  return <Wrapper >
    <Button cancel onClick={onCancel}>
      <img className='background' src='/new-image/Mobile/buttonFrame_gray.png'/>
      <span>취소</span>
    </Button>
    <div className='item'>
      <Gauge ref={ref}>
        <div ref={labelRef} className="label">{min}</div>
        <div className='button-wrapper'>
          <BetButton onClick={()=>setAmount(max)}>
            <img className='background' src='/new-image/Mobile/button_bet.png'/>
            <span>최대</span>
          </BetButton>
          <BetButton onClick={()=>setAmount(pot)}>
            <img className='background' src='/new-image/Mobile/button_bet.png'/>
            <span>팟</span>
          </BetButton>
          <BetButton onClick={()=>setAmount(bb*3)}>
            <img className='background' src='/new-image/Mobile/button_bet.png'/>
            <span>3BB</span>
          </BetButton>
          <BetButton onClick={()=>setAmount(min)}>
            <img className='background' src='/new-image/Mobile/button_bet.png'/>
            <span>최소</span>
          </BetButton>
        </div>
        <Fill ref={fillRef}/>
        <Thumb ref={thumbRef}>
          <div className="chip">

          </div>
        </Thumb>
      </Gauge>
      <Button onClick={handleConfirm}>
        <img className='background' src='/new-image/Mobile/buttonFrame_green.png'/>
        <span>확인</span>
      </Button>
    </div>

  </Wrapper>;
}
export default BetGaugeVertical;
