import {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import produce from 'immer';
import Modal from "./common/Modal";
import {requestGetUserProfile, requestModifyUserProfile, requestMyInfo, requestReport} from "../api";
import {userProfileModel} from "../dataset";
import useDialog from "../hooks/useDialog";
import {useRecoilState} from "recoil";
import {myInfoState} from "../recoil/MyInfo";
import numbro from "numbro";
import CircleProgress from "./common/CircleProgress";

const Content = styled.div`
  padding: 8px;
  color: #FFF;
`;

const Border = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: #2D2D2D;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  > .image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  > .info {
    flex: 1;

    > .name {
      font-size: 16px;
      font-weight: 600;
    }

    > .hand {
      font-size: 12px;
      margin-top: 8px;

      > span {
        opacity: 0.5;
        margin-right: 4px;
      }

    }
  }
`;

const ModifyButton = styled.div`
  width: 66px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
  background: #1F1F1F;
`;

const ReportButton = styled(ModifyButton)`
  color: #FF2525;
  border: 1px solid #FF2525;
  background: transparent;
`;

const Stats = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  > .grid {
    display: grid;
    grid-template-columns: repeat(3, 85px);
    grid-auto-rows: auto;
    grid-gap: 20px 8px;
    padding: 16px 4px;
  }

  > .caption {
    opacity: 0.5;
  }
`;

const StatItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
      font-weight: 800;
    }
  }
  .progress {
    width: 75px;
    height: 75px;
  }
`;


const ButtonWrapper = styled.div`
  display: flex;
  gap: 11px;
  position: relative;

  > div {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    >span {
      position: relative;
      z-index: 1;
    }
    >.background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: fill;
      z-index: 0;
    }
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  margin-top: 8px;
`;

const NicknameForm = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
      margin-bottom: 8px;
    }

    &:last-child {
      display: flex;

      > input {
        flex: 1;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        background: transparent;
        border: none;
        outline: none;
      }

      > .limit {
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }


`;

const SelectImage = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  > div {
    &:first-child {
      font-size: 12px;
      font-weight: 500;
      opacity: 0.5;
    }
  }

  > .grid {
    display: grid;
    grid-template-columns: repeat(4, 64px);
    grid-auto-rows: auto;
    grid-gap: 8px;
    padding: 16px 0;

    > .profile-image-wrapper {
      position: relative;
      width: 64px;
      height: 64px;

      > .profile-image {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        z-index: 1;
        //border: 2px solid transparent;
      }

      > .selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 50%;
        height: 100%;
        z-index: 5;
        border: 2px solid #2F99FB;
        background: rgba(16, 16, 18, 0.50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const toPercent = (v: number | null): string => {
  return numbro((v || 0) * 100).format({
    trimMantissa: true,
    mantissa: 4
  });
};

function ProfileModal(
  {
    groupId,
    userId,
    onClose
  }: {
    groupId?: number,
    userId: number,
    onClose: () => void;
  }
) {
  const [myInfo, setMyInfo] = useRecoilState(myInfoState);

  const [modify, setModify] = useState<boolean>(false);
  const [profile, setProfile] = useState<userProfileModel>();

  const {openDialog} = useDialog();

  const isMe = useMemo(() => {
    return userId === myInfo?.userId;
  }, [userId, myInfo?.userId]);

  const profileImg = useMemo<string>(() => {
    const idx = Number(profile?.profileImg || '0');
    return `/new-image/profile/profile_0${idx+1}.png`
  }, [profile?.profileImg]);

  const handleConfirm = useCallback(async () => {
    if (!profile?.nickname) {
      openDialog({
        title: '오류',
        text: '닉네임을 입력해주세요.'
      });
      return;
    }

    const res = await requestModifyUserProfile(profile.nickname, profile.profileImg);
    if (!res) {
      openDialog({
        title: '오류',
        text: `프로필을 변경하는 중 오류가 발생했습니다.`
      });
    }

    requestMyInfo().then((v: any) => setMyInfo(v.info));
    setModify(false);
  }, [profile]);

  const handleChange = useCallback((e: any) => {
    if (e.target.value.length > 8) {
      return;
    }

    setProfile(produce(d => {
      if (d != null) {
        d.nickname = e.target.value;
      }
    }));
  }, []);

  const handleSelectImage = useCallback((idx: number) => {
    setProfile(produce(d => {
      if (d) {
        d.profileImg = idx.toString();
      }
    }));
  }, []);

  const handleReport = useCallback(() => {
    if (!profile || !groupId) {
      return;
    }

    openDialog({
      title: "유저신고",
      text: profile.nickname + '님을 신고하시겠습니까?',
      confirm: true,
      onConfirm: async () => {
        await requestReport(groupId, profile.userId);
        openDialog({
          title: "안내",
          text: "신고를 완료했습니다.",
        })
      }
    });
  }, [profile]);

  useEffect(() => {
    if (userId === -1) {
      return;
    }

    requestGetUserProfile(userId).then((profile: userProfileModel) => {
      if (!profile.nickname) {
        onClose && onClose();
        return;
      }

      setProfile(profile);
    });
  }, [userId]);

  if (!profile) {
    return null;
  }

  return <Modal hasBorder={true}>
    {
      !modify ? (
        <Content>
          <Profile>
            <img className="image" src={profileImg}/>
            <div className="info">
              <div className="name">{profile.nickname}</div>
            </div>
            {
              !isMe &&
                <ReportButton onClick={handleReport}>신고</ReportButton>
            }
          </Profile>
          <Border/>
          <Stats>
            <div className="grid">
              <StatItem>
                <div>HAND</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={100}
                  max={100}
                  text={profile.handCount.toString()}/>
              </StatItem>
              <StatItem>
                <div>VPIP</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={Number(toPercent(profile.vpip))}
                  max={100}
                  text={`${toPercent(profile.vpip)}%`}/>
              </StatItem>
              <StatItem>
                <div>WIN RATE</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={Number(toPercent(profile.winRate))}
                  max={100}
                  text={`${toPercent(profile.winRate)}%`}/>
              </StatItem>
              <StatItem>
                <div>PFR</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={Number(toPercent(profile.pfr))}
                  max={100}
                  text={`${toPercent(profile.pfr)}%`}/>
              </StatItem>
              <StatItem>
                <div>3BET</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={Number(toPercent(profile.bet3))}
                  max={100}
                  text={`${toPercent(profile.bet3)}%`}/>
              </StatItem>
              <StatItem>
                <div>ATS</div>
                <CircleProgress
                  radius={33}
                  className={'progress'}
                  value={Number(toPercent(profile.ats))}
                  max={100}
                  text={`${toPercent(profile.ats)}%`}/>
              </StatItem>
            </div>
          </Stats>
          <ButtonWrapper>
            <div onClick={onClose}>
              <span>닫기</span>
              <img className='background' src='/new-image/Mobile/buttonFrame_bet_cancel_full.png'/>
            </div>
          </ButtonWrapper>
        </Content>
      ) : (
        <Content>
          <ModalTitle>정보 변경</ModalTitle>
          <NicknameForm className="form">
            <div>닉네임</div>
            <div>
              <input value={profile.nickname} onChange={handleChange}/>
              <div className="limit">{profile.nickname.length}/8</div>
            </div>
          </NicknameForm>
          <Border/>
          <SelectImage>
            <div>프로필 이미지</div>
            <div className="grid">
              {
                Array.from({length: 5}).map((_, i) => (
                  <div className="profile-image-wrapper">
                    {
                      (Number(profile.profileImg || 0) === i) && <div className='selected'>
                            <img src='/images/ic_option_checked.svg'/>
                        </div>
                    }
                    <img key={i} className='profile-image' src={`/images/profile/${i}.svg`}
                         data-selected={Number(profile.profileImg || 0) === i}
                         onClick={() => handleSelectImage(i)}/>
                  </div>
                ))
              }
            </div>
          </SelectImage>
          <ButtonWrapper>
            <div onClick={() => setModify(false)}>취소</div>
            <div onClick={handleConfirm}>완료</div>
          </ButtonWrapper>
        </Content>
      )
    }
  </Modal>;
}

export default ProfileModal;
