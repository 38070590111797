import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerRate = styled.div<{
  rate: number
}>`
  padding: 5px 10px;
  font-size: 14px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: -0.24px;
  border-radius: 5px;
  color: black;
  @media ${MEDIA_DESKTOP} {
    font-size: 18px;
  }
  ${p => p.rate >= 0 && p.rate <= 34 && `
    background-image: -moz-linear-gradient( 90deg, rgb(57,161,57) 0%, rgb(118,224,47) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(57,161,57) 0%, rgb(118,224,47) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(57,161,57) 0%, rgb(118,224,47) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
  `};

  ${p => p.rate >= 35 && p.rate <= 67 && `
    background-image: -moz-linear-gradient( 90deg, rgb(30,91,255) 0%, rgb(63,171,255) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(30,91,255) 0%, rgb(63,171,255) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(30,91,255) 0%, rgb(63,171,255) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
  `};

  ${p => p.rate >= 68 && p.rate <= 100 && `
    background-image: -moz-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
  `};
`

function WinningRate({rate, ...p}: {
  rate?: number
}) {
  if (rate === undefined) {
    return null;
  }

  return <Wrapper  {...p}>
    <InnerRate rate={rate}>
      {rate}%
    </InnerRate>
  </Wrapper>
}

export default WinningRate;
