import styled from "styled-components";
import {useEffect, useMemo, useRef, useState} from "react";

const CircleProgressWrapper = styled.div<{ CIRCUMFERENCE: number; percent: number }>`{
  width: 100%;
  height: 100%;
  position: relative;

  .circle_progress_wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .circle_progress {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
  }

  .frame, .bar {
    fill: none;
  }

  .frame {
    stroke: black;
  }

  .bar {
    transition: all 1s linear;
    stroke: #03c75a;;
    stroke-linecap: round;
    stroke-dashoffset: ${p => p.percent};
    stroke-dasharray: ${p => p.CIRCUMFERENCE};
  }
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size : 12px;
    font-weight: bold;
    color: white;
  }
}`

interface CircleProgressProps {
  radius: number;
  className: string;
  value: number;
  max: number;
  text?: string;
}

const CircleProgress = ({radius, className, value, max, text}: CircleProgressProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)


  const CIRCUMFERENCE = 2 * Math.PI * radius;

  const dashoffset = useMemo(() => {
    const progress = value / max;
    return CIRCUMFERENCE * (1-progress);
  }, [value, max, CIRCUMFERENCE]);

  return <CircleProgressWrapper ref={wrapperRef} className={className} CIRCUMFERENCE={CIRCUMFERENCE} percent={dashoffset}>
    <svg className="circle_progress">
      <circle className="frame" cx="50%" cy="50%" r={radius} strokeWidth={6}/>
      <circle className="bar" cx="50%" cy="50%" r={radius} strokeWidth={6}/>
    </svg>
    {
      text && <div className='text'>{text}</div>
    }
  </CircleProgressWrapper>
}

export default CircleProgress;
