import styled from "styled-components";
import {useMemo} from "react";
import {BET_TYPE} from "../../dataset";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{
  act: number
}>`
  width: 52px;
  position: absolute;
  min-width: 52px;
  border-radius: 16px 16px 16px 0px;
  background: rgba(16, 16, 18, 0.80);
  box-shadow: 0 0 6px 0 rgba(70, 155, 255, 0.25);
  text-align: center;
  font-size: 14px;
  padding: 6px 2px 6px 0;
  font-style: italic;
  top: 10px;
  font-weight: 800;
  
  @media ${MEDIA_DESKTOP} {
    width: 84px;
    padding: 8px 2px 8px 0px;
    border-radius: 24px 24px 24px 0px;
    min-width: 80px;
    font-size: 22px;
  }

  span {
    display: block;
    width: 100%;
    line-height: 1;
    color: #FFF;
  }
  ${p => p.act === BET_TYPE.BET && `
      background-image: -moz-linear-gradient( 90deg, rgb(13,65,213) 0%, rgb(38,147,231) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(13,65,213) 0%, rgb(38,147,231) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(13,65,213) 0%, rgb(38,147,231) 100%);
      box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
      span {
        color: black;
      }
  `};
  ${p => p.act === BET_TYPE.FOLD && `
      background-image: -moz-linear-gradient( 90deg, rgb(57,60,57) 2%, rgb(85,84,88) 97%);
      background-image: -webkit-linear-gradient( 90deg, rgb(57,60,57) 2%, rgb(85,84,88) 97%);
      background-image: -ms-linear-gradient( 90deg, rgb(57,60,57) 2%, rgb(85,84,88) 97%);
      box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);

    span {
      color: white;
    }
  `};

  ${p => (p.act === BET_TYPE.CALL || p.act === BET_TYPE.CHECK) && `
    background-image: -moz-linear-gradient( 90deg, rgb(52,143,54) 0%, rgb(112,213,44) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(52,143,54) 0%, rgb(112,213,44) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(52,143,54) 0%, rgb(112,213,44) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);
    span {
      color: black;
    }
  `};

  ${p => p.act === BET_TYPE.RAISE && `
    background-image: -moz-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(238,159,56) 0%, rgb(255,240,0) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);      
    span {
      color: black;
    }
  `};

  ${p => p.act === BET_TYPE.ALLIN && `
    background-image: -moz-linear-gradient( 90deg, rgb(156,14,27) 0%, rgb(241,61,37) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(156,14,27) 0%, rgb(241,61,37) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(156,14,27) 0%, rgb(241,61,37) 100%);
    box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.3);    
    span {
      color: white;
    }
  `};
`;

function ActionTag({act}: {
  act: number
}) {
  const typeText = useMemo(() => {
    switch (act) {
      case BET_TYPE.FOLD:
        return '폴드';
      case BET_TYPE.CALL:
        return '콜';
      case BET_TYPE.CHECK:
        return '체크';
      case BET_TYPE.RAISE:
        return '레이즈';
      case BET_TYPE.ALLIN:
        return '올-인';
      case BET_TYPE.BET:
        return '벳';
      case BET_TYPE.SB:
        return 'SB';
      case BET_TYPE.BB:
        return 'BB';
      case BET_TYPE.STR:
        return 'STR';
    }
  }, [act]);

  return <Wrapper className="action" act={act}>
    <span>{typeText}</span>
  </Wrapper>;
}

export default ActionTag;
