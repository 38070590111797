import styled from "styled-components";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";

const Wrapper = styled.div`
  width: 42px;
  height: 56px;
  perspective: 1000px;
  display: inline-block;
`;

const Card = styled.div<{
  flipped?: boolean
}>`
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.5s linear;
  transform-style: preserve-3d;
  will-change: transform;

  ${p => p.flipped && `
    transform: rotateY(-180deg);
  `};

  > img {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(-180deg);
    }
  }
`;


function PokerCard(
  {
    card,
    flip,
    delay = 0,
    ...p
  }: {
    card?: number,
    flip?: boolean,
    delay?: number
    [k: string]: any
  }
) {
  const gameOption = useRecoilValue(gameOptionState);
  const [flipped, setFlipped] = useState(delay === -1 && flip);

  useEffect(() => {
    if (flip) {
      setTimeout(() => {
        setFlipped(true);
      }, delay || 10);
    } else {
      setFlipped(false);
    }
  }, [flip, delay]);

  return <Wrapper {...p}>
    <Card flipped={flipped}>
      <img src={`/new-image/card/card_back.png`}/>
      <img src={`/images/${card !== undefined ? `card/style-${gameOption.cardStyle}/${card}` : 'card_back'}.png`}/>
    </Card>
  </Wrapper>;
}

export default PokerCard;
