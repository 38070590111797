import {atom} from "recoil";

export interface GameOptionProps {
  tableStyle: 'blue' | 'red' | 'green'
  cardStyle: 1 | 2 | 3
  alwaysBB: boolean
}

const localStorageEffect =
  (key: string) =>
    ({ setSelf, onSet }: any) => {
      const savedValue = localStorage.getItem(key);
      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

export const gameOptionState = atom<GameOptionProps>({
  key: 'gameOptionState',
  default: {
    tableStyle: 'blue',
    cardStyle: 1,
    alwaysBB: false
  },
  effects: [localStorageEffect('gameOptionState')],
});
